import React, { useEffect, useState } from "react";
import FounderImageFirst from "../../../assets/drem.png";
import FounderImageSecond from "../../../assets/drem1.png";
import FounderImageThird from "../../../assets/drem2.png";
import FounderImageFourth from "../../../assets/drem3.png";
import righta from "../../../assets/lefta.png";
import lefta from "../../../assets/righta.png";


import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import axiosInstance from "../../../apiInstances/axiosInstance";
import { BACKEND_BASE_URL } from "../../../apiInstances/baseurl";

const Developmentcart = () => {
  const [ClientSliderData, setclientSliderData] = useState([]);

  const agentDetail = () => {
    axiosInstance
      .get(`/latestagent`)
      .then((res) => {
        const myData = res?.data?.data;
        const arrData = myData.map((e, i) => {
          return {
            id: 1,
            clientImage: e?.profileImg,
            head: e?.name,
            para: "6 Watermans Quay, Barangaroo, NSW 200",
            propties: e?.count,
          };
        });
        setclientSliderData(arrData);
      })
      .then((error) => console.log(error));
  };
  useEffect(() => {
    agentDetail();
  }, []);

  // const ClientSliderData = [
  //   {
  //     id: 1,
  //     clientImage: FounderImageFirst,
  //     head: "One Sydney Harbour",
  //     para: "6 Watermans Quay, Barangaroo, NSW 200",
  //     propties: 9,
  //   },
  //   {
  //     id: 2,
  //     clientImage: FounderImageSecond,
  //     head: "Tapestry",
  //     para: "39, DARRAMBAL ST, Surfers Paradise, QLD 4217",
  //     propties: 5,
  //   },
  //   {
  //     id: 3,
  //     clientImage: FounderImageThird,
  //     head: "200 Hedges Ocean Houses",
  //     para: "200 Hedges Avenue, Mermaid Beach, QLD 4218",
  //     propties: 7,
  //   },
  //   {
  //     id: 4,
  //     clientImage: FounderImageFourth,
  //     head: "One Sydney Harbour",
  //     para: "6 Watermans Quay, Barangaroo, NSW 200",
  //     propties: 12,
  //   },
  //   {
  //     id: 5,
  //     clientImage: FounderImageFirst,
  //     head: "Tapestry",
  //     para: "39, DARRAMBAL ST, Surfers Paradise, QLD 4217",
  //     propties: 6,
  //   },
  // ];
  return (
    <>
      <div className="mt-20 lg:mx-40">
        <div className="flex justify-between">
          <h2 className="font-semibold xl:text-3xl lg:text-2xl md:text-xl text-lg  mb-2 text-[#262626]">
            New Agents
          </h2>
          <div className="flex my-auto mb-auto gap-2">
            <div className="">
              <img
                className="prev1 cursor-pointer w-10  "
                src={righta}
                alt=""
              />
            </div>
            <img className="next2 cursor-pointer w-10" src={lefta} alt="" />
          </div>
        </div>
        <p className="lg:text-base text-sm text-[#737373] pb-8">
          {/* A property description is the written portion of a real estate listing
          that describes the details of a home for sale or lease. */}
        </p>
      </div>
      <div className="Agent lg:mx-40">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 70,
            },
            1460: {
              slidesPerView: 4,
              spaceBetween: 150,
            },
           
          }}
          modules={[Navigation]}
          navigation={{
            prevEl: ".prev1",
            nextEl: ".next2",
          }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
        >
          <div className="">
            {ClientSliderData.map((e, index) => (
              <SwiperSlide key={index}>
                <div className="relative rounded-xl flex justify-center w-80 h-80 ">
                  <div>
                    <p className="absolute m-2 bg-white inline-block p-2 lg:text-base text-sm rounded-md cursor-pointer">
                      {e.propties} Properties
                    </p>
                  </div>
                  <div className="absolute text-black -bottom-16 rounded-b-md text mx-auto bg-white w-full p-3">
                    <h2 className="font-medium lg:text-xl md:text-lg text-md ">
                      {e.head}
                    </h2>
                    <p className="lg:text-sm text-xs">{e.para}</p>
                  </div>
                  <img
                    className="rounded-xl 2xl:w-[27rem] w-auto  object-fill aspect-square bg-white"
                    src={`${BACKEND_BASE_URL}${e.clientImage}`}
                    alt="img"
                  ></img>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default Developmentcart;
