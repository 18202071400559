// import React, { useEffect, useRef, useState } from "react";
// import Layout2 from "../../Layouts/Layout2";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import Googlemap from "../common/GoogleMap/Googlemap";
// import i1 from "../../assets/detail1.png";
// import i2 from "../../assets/detail2.png";
// import i3 from "../../assets/detail3.png";
// import agent01 from "../../assets/agent01.png";
// import detailLogo from "../../assets/detailLogo.png";
// import shareRed from "../../assets/shareRed.png";
// import heart from "../../assets/emptyHeart.png";
// import fullRedHeart from "../../assets/fillHeart.png";
// import historyGray from "../../assets/historyGray.png";
// import sideArrowRed from "../../assets/sideArrowRed.png";
// import startGray from "../../assets/startGray.png";
// import bedroom from "../../assets/bedroom_icon.png";
// import car from "../../assets/car_icon.png";
// import square from "../../assets/square.png";
// import home from "../../assets/home.png";
// import shower from "../../assets/shower_icon.png";
// import shareBlue from "../../assets/shareBlue.png";
// import estimateGray from "../../assets/estimateGray.png";
// import map from "../../assets/map.png";
// import callBlue from "../../assets/callBlue.png";
// import locationRed from "../../assets/locationRed.png";
// import news1 from "../../assets/news1.png";
// import news2 from "../../assets/news2.png";
// import logoSmall from "../../assets/logoSmall.png";
// import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
// import { BACKEND_BASE_URL } from "../../apiInstances/baseurl";
// import ImageGallery from "react-image-gallery";
// import useOnClickOutside from "../../Hooks/useOnClickOutside/useOnClickOutside";
// import { toast } from "react-toastify";

// const Rating = (props) => {
//   const rating = props.rating;
//   const stars = [];
//   const fullStars = Math.floor(rating); // Number of full stars
//   const hasHalfStar = rating - fullStars >= 0.5; // Check if there is a half star

//   for (let i = 0; i < 5; i++) {
//     if (i < fullStars) {
//       stars.push(<i key={i} className="fa fa-star" />);
//     } else if (i === fullStars && hasHalfStar) {
//       stars.push(<i key={i} className="fa fa-star-half-o" />);
//     } else {
//       stars.push(<i key={i} className="fa fa-star-o" />);
//     }
//   }

//   return <div className="rating text-yellow-400 gap-2 flex">{stars}</div>;
// };

// const DetailedViewProperty = () => {
//   const navigate = useNavigate();
//   const ref = useRef(null);
//   const { id } = useParams();

//   const emailRegex =
//     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//   const [AuthPopUp, setAuthPopUp] = useState(false);

//   const [GallaryView, setGallaryView] = useState(false);
//   useOnClickOutside(ref, () => setGallaryView(false));

//   const isAuthanticate = localStorage.getItem("Token");

//   const isfavorite = async (i) => {
//     await axiosInstanceAuth
//       .post("addToFavorites", {
//         id: i?._id,
//       })
//       .then((res) => {
//         const mydata = res?.data?.data;

//         if (res?.data?.status) {
//           toast.success(res?.data?.message);
//         } else {
//           toast.error(res?.data?.message);
//         }
//       })
//       .catch((err) => {
//         console.log("err --->", err);
//       });
//   };
//   const [isSticky, setIsSticky] = useState(false);
//   const [PropertyData, setPropertyData] = useState({});

//   const [PropertyImages, setPropertyImages] = useState([]);
//   const [agentData, setAgentData] = useState({});

//   const [EnqiryData, setEnqiryData] = useState({
//     message: "",
//     first_name: "",
//     last_name: "",
//     mobile_no: "",
//     email: "",
//   });

//   const onChangeInput = (e) => {
//     const { name, value } = e.target;
//     setEnqiryData({ ...EnqiryData, [name]: value });
//   };

//   useEffect(() => {
//     ViewPropertyDatabyID(id);
//   }, []);

//   const ViewPropertyDatabyID = async (id) => {
//     await axiosInstanceAuth
//       .post(`Agency/viewProperty`, {
//         id: id,
//       })
//       .then((res) => {
//         const mydata = res?.data?.data;
//         const myagentdata = res?.data?.agentData;

//         if (res?.data?.status) {
//           setPropertyData({
//             ...mydata,
//             mydata,
//             favourite: localStorage
//               .getItem("Saved")
//               ?.split(",")
//               .includes(mydata?._id),
//           });
//           setAgentData(myagentdata);

//           const frontPageImg = mydata?.frontPageImg.map((obj, i) => ({
//             original: `${BACKEND_BASE_URL}${obj}`,
//           }));
//           const propertyImg = mydata?.propertyImg.map((obj, i) => ({
//             original: `${BACKEND_BASE_URL}${obj}`,
//           }));
//           const florePlansImg = mydata?.florePlansImg.map((obj, i) => ({
//             original: `${BACKEND_BASE_URL}${obj}`,
//           }));

//           setPropertyImages([
//             ...frontPageImg,
//             ...propertyImg,
//             ...florePlansImg,
//           ]);
//         } else {
//         }
//       })
//       .catch((err) => {
//         console.log("err --->", err);
//       });
//   };

//   const SubmitReqest = () => {
//     try {
//       if (EnqiryData?.message === "") {
//         toast.error("Please enter a Message");
//       } else if (EnqiryData?.first_name === "") {
//         toast.error("Please enter a First Name");
//       } else if (EnqiryData?.last_name === "") {
//         toast.error("Please enter a Last Name");
//       } else if (EnqiryData?.mobile_no === "") {
//         toast.error("Please enter a Mobile Number");
//       } else if (!emailRegex?.test(EnqiryData?.email)) {
//         toast.error("Please enter a valid Email");
//       } else {
//         axiosInstanceAuth
//           .post(`sendEnquiry`, {
//             property_id: id,
//             agent_id: agentData?.id,
//             message: EnqiryData?.message,
//             first_name: EnqiryData?.first_name,
//             last_name: EnqiryData?.last_name,
//             mobile_no: EnqiryData?.mobile_no,
//             email: EnqiryData?.email,
//           })
//           .then((res) => {
//             if (res?.data?.status) {
//               toast.success("Enqiry Submited Successfuly");
//             } else {
//               toast.error(res?.data?.message);
//             }
//           })
//           .catch((err) => {
//             console.log("------>> Error", err);
//           });
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY;
//       if (scrollPosition >= 1100 && scrollPosition <= 1600) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//   return (
//     <>
//       <Layout2>
//         <div className="px-5 pt-28">
//           <div className="container mx-auto">
//             {/* ||---------- Start Section 1 ----------|| */}
//             <div className="">
//               <div className="text-start text-[#171717] font-semibold text-xs md:text-sm">
//                 <span className="uppercase">
//                   {PropertyData?.status === "active"
//                     ? "buy"
//                     : PropertyData?.status}
//                 </span>
//                 → {PropertyData?.municipality} → {PropertyData?.suburb} →
//                 {PropertyData?.street_address_name}
//                 {PropertyData?.street_address_number}
//               </div>

//               {/* ---------- Property Details Card ---------- */}
//               <div className={`grid place-items-center my-5 md:my-10`}>
//                 <div className="flex flex-col bg-white rounded-lg shadow-md hover:shadow-lg p-4 md:p-6">
//                   {/* ---------- Images ---------- */}

//                   {GallaryView ? (
//                     <div
//                       ref={ref}
//                       className={`w-full h-full flex justify-center items-center gap-4`}
//                     >
//                       <ImageGallery items={PropertyImages} fullScreen />
//                     </div>
//                   ) : (
//                     <div
//                       onClick={() => setGallaryView(true)}
//                       className={`w-full h-full flex justify-center items-center gap-4`}
//                     >
//                       <LazyLoadImage
//                         src={`${BACKEND_BASE_URL}${PropertyData?.frontPageImg?.[0]}`}
//                         alt=""
//                         srcSet={`${BACKEND_BASE_URL}${PropertyData?.frontPageImg?.[0]}`}
//                         loading="lazy"
//                         effect="blur"
//                         // style={{ width: "inherit !important" }}
//                         className="w-full object-center rounded-lg cursor-pointer"
//                       />
//                       <div className="h-full hidden md:flex flex-col justify-center items-center gap-4">
//                         <LazyLoadImage
//                           style={{ width: "inherit" }}
//                           src={`${BACKEND_BASE_URL}${PropertyData?.propertyImg?.[0]}`}
//                           alt=""
//                           srcSet={`${BACKEND_BASE_URL}${PropertyData?.propertyImg?.[0]}`}
//                           loading="lazy"
//                           effect="blur"
//                           className="w-full h-[50%] max-w-[150px] lg:max-w-[250px] rounded-lg cursor-pointer"
//                         />
//                         <div className="relative h-[50%] max-w-[150px] lg:max-w-[250px]">
//                           <LazyLoadImage
//                             style={{ width: "inherit" }}
//                             src={`${BACKEND_BASE_URL}${PropertyData?.florePlansImg?.[0]}`}
//                             alt=""
//                             srcSet={`${BACKEND_BASE_URL}${PropertyData?.florePlansImg?.[0]}`}
//                             loading="lazy"
//                             effect="blur"
//                             className="brightness-50 w-full rounded-lg cursor-pointer"
//                           />
//                           <div className="h-full w-full grid place-content-center text-white font-semibold text-4xl absolute inset-0 opacity-100 cursor-pointer">
//                             {PropertyImages?.length > 3
//                               ? `+${PropertyImages?.length - 3}`
//                               : null}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   )}

//                   {/* ---------- Details ---------- */}
//                   <div className="">
//                     <div className="flex justify-between items-start gap-5 mt-5 md:mt-7">
//                       <div className="text-[#171717] font-bold text-lg md:text-2xl lg:text-3xl xl:text-4xl">
//                         {PropertyData?.street_address_number},
//                         {PropertyData?.street_address_name},
//                         {PropertyData?.suburb}, {PropertyData?.municipality}
//                       </div>
//                       <div className="flex justify-center items-center gap-2">
//                         <LazyLoadImage
//                           src={shareRed}
//                           alt="icon"
//                           srcSet={shareRed}
//                           loading="lazy"
//                           effect="blur"
//                           className="bg-[#FFEAEF] border border-[#FA979A] rounded-full p-1 sm:p-2 md:p-3 cursor-pointer"
//                         />

//                         {PropertyData?.favourite ? (
//                           <LazyLoadImage
//                             src={fullRedHeart}
//                             alt="icon"
//                             srcSet={fullRedHeart}
//                             loading="lazy"
//                             effect="blur"
//                             onClick={(e) => {
//                               if (!isAuthanticate) {
//                                 setAuthPopUp(true);
//                               } else {
//                                 isfavorite(PropertyData);
//                               }
//                             }}
//                             className="bg-[#FFEAEF] border border-[#FA979A] rounded-full p-1 sm:p-2 md:p-3 cursor-pointer"
//                           />
//                         ) : (
//                           <LazyLoadImage
//                             src={heart}
//                             alt="icon"
//                             srcSet={heart}
//                             loading="lazy"
//                             effect="blur"
//                             onClick={(e) => {
//                               if (!isAuthanticate) {
//                                 setAuthPopUp(true);
//                               } else {
//                                 isfavorite(PropertyData);
//                               }
//                             }}
//                             className="bg-[#FFEAEF] border border-[#FA979A] rounded-full p-1 sm:p-2 md:p-3 cursor-pointer"
//                           />
//                         )}
//                       </div>
//                     </div>

//                     <div className="flex flex-wrap justify-start items-center gap-2 mt-4">
//                       <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
//                         <img
//                           src={bedroom}
//                           alt="location"
//                           className="w-4 md:w-5 mr-3"
//                         />
//                         <div className="font-semibold text-[#737373] text-xs md:text-base">
//                           {PropertyData?.Bedrooms}
//                         </div>
//                       </div>
//                       <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
//                         <img
//                           src={shower}
//                           alt="bedroom"
//                           className="w-4 md:w-5 mr-3"
//                         />
//                         <div className="font-semibold text-[#404040] text-xs md:text-sm">
//                           {PropertyData?.Bathrooms}
//                         </div>
//                       </div>
//                       <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
//                         <img
//                           src={car}
//                           alt="shower"
//                           className="w-4 md:w-5 mr-3"
//                         />
//                         <div className="font-semibold text-[#404040] text-xs md:text-sm">
//                           {PropertyData?.garage_spaces}
//                         </div>
//                       </div>
//                       <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
//                         <img
//                           src={square}
//                           alt="car"
//                           className="w-4 md:w-5 mr-3"
//                         />
//                         <div className="font-semibold text-[#404040] text-xs md:text-sm">
//                           {PropertyData?.house_size_square}
//                         </div>
//                       </div>
//                       <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
//                         <img src={home} alt="car" className="w-4 md:w-5 mr-3" />
//                         <div className="font-semibold text-[#404040] text-xs md:text-sm">
//                           {PropertyData?.house_size}
//                         </div>
//                       </div>
//                     </div>

//                     <div className="flex flex-col md:flex-row justify-between items-start gap-3 mt-5 md:mt-7">
//                       <div className="flex flex-col justify-start gap-2">
//                         <div className="text-[#171717] font-semibold text-sm md:text-lg lg:text-xl">
//                           $ {PropertyData?.price_display}
//                         </div>
//                         <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base">
//                           Sold on 07 Jul 20222
//                         </div>
//                       </div>
//                       <div className="flex justify-center items-center gap-2 bg-white border border-[#171717]  rounded-lg p-2 md:p-3">
//                         <LazyLoadImage
//                           src={historyGray}
//                           alt="icon"
//                           srcSet={historyGray}
//                           loading="lazy"
//                           effect="blur"
//                           className="w-4 cursor-pointer"
//                         />
//                         <div className="text-[#171717] font-semibold text-xs md:text-sm lg:text-base">
//                           Property History
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* ---------- End Section 1 ---------- */}

//             {/* ||---------- Start Section 2 ----------|| */}

//             <div className="grid grid-cols-1 xl:grid-cols-7 place-content-start gap-10 mb-10">
//               {/* ------ Left Part ------ */}
//               <div className="grid col-span-1 xl:col-span-5">
//                 {/* ------ Cards ------ */}
//                 <div className="w-full flex flex-col lg:flex-row justify-start gap-5">
//                   <div
//                     className={`w-auto lg:w-[50%] flex flex-col justify-start items-start bg-white rounded-2xl shadow-md hover:shadow-lg p-5`}
//                   >
//                     <div className="text-[#171717] font-semibold text-md md:text-xl">
//                       Looking to buy a place like this?
//                     </div>
//                     <div className="w-full flex justify-start items-center mt-1">
//                       <img
//                         src={estimateGray}
//                         alt="icon"
//                         className="mr-2 cursor-pointer"
//                       />
//                       <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base underline">
//                         Estimate repayments
//                       </div>
//                     </div>
//                   </div>
//                   <div
//                     className={`w-auto lg:w-[50%] flex flex-col justify-start items-start bg-white rounded-2xl shadow-md hover:shadow-lg p-5`}
//                   >
//                     <div className="text-[#171717] font-semibold text-md md:text-xl">
//                       Looking to sell a similar property?
//                     </div>
//                     <div className="w-full flex justify-between items-center gap-2 mt-1">
//                       <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base underline">
//                         See how the selling agwes
//                       </div>
//                       <img
//                         src={shareBlue}
//                         alt="icon"
//                         className="p-1 cursor-pointer"
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 {/* ------ Google Map ------ */}
//                 <div className="flex flex-col bg-white rounded-lg shadow-md hover:shadow-lg p-4 mt-6 md:mt-10">
//                   {/* <LazyLoadImage
//                   src={map}
//                   alt="icon"
//                   srcSet={map}
//                   loading="lazy"
//                   effect="blur"
//                   className="cursor-pointer"
//                 /> */}
//                   <div className="h-[82vh] w-full custStyle">
//                     <Googlemap
//                       id={id}
//                       location={PropertyData?.location?.coordinates}
//                     />
//                   </div>
//                   <div className="text-[#171717] font-bold text-lg md:text-xl lg:text-xl mt-4 md:mt-6 px-0 md:px-5">
//                     Sold By Paul Donahoe, Professionals Collective
//                   </div>
//                   <div className="text-[#737373] font-semibold text-sm md:text-base lg:text-lg mt-2 px-0 md:px-5">
//                     {PropertyData?.street_address_number}
//                     {PropertyData?.street_address_name}, {PropertyData?.suburb}
//                   </div>
//                   <div className="text-[#525252] font-medium text-xs md:text-sm lg:text-base mt-4 md:mt-6 px-0 md:px-5">
//                     {PropertyData?.heading}
//                   </div>
//                   <div className="text-[#525252] font-medium text-xs md:text-sm lg:text-base mt-3 px-0 md:px-5">
//                     {PropertyData?.discription}
//                   </div>
//                   <div className="text-[#525252] font-medium text-xs md:text-sm lg:text-base mt-3 px-0 md:px-5">
//                     This immaculate residence provides a modern open floor plan
//                     that makes sense whilst still offering the larger family
//                     multiple living options necessary for everyday living.
//                   </div>
//                   <div className="flex justify-start items-center text-[#E5002A] font-medium text-xs md:text-sm lg:text-base cursor-pointer my-4 md:my-8 px-0 md:px-5">
//                     Read More
//                     <LazyLoadImage
//                       src={sideArrowRed}
//                       alt="icon"
//                       srcSet={sideArrowRed}
//                       loading="lazy"
//                       effect="blur"
//                       className="ml-2"
//                     />
//                   </div>
//                 </div>

//                 {/* ------------inspection start---------------- */}
//                 <div
//                   className={`w-auto flex flex-col justify-start items-start bg-white rounded-2xl shadow-md hover:shadow-lg p-5 mt-10`}
//                 >
//                   <div className="text-[#171717] font-semibold text-md md:text-xl">
//                     Inspections
//                   </div>
//                   <div
//                     className="w-full flex justify-between items-center gap-2 mt-1"
//                     onClick={() => navigate(`/inspection`)}
//                   >
//                     <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base underline cursor-pointer">
//                       There are no upcoming inspections for this property.
//                       Contact the agent to see this place.
//                     </div>
//                     <img
//                       src={shareBlue}
//                       alt="icon"
//                       className="p-1 cursor-pointer"
//                     />
//                   </div>
//                 </div>
//                 {/* ------------inspection end---------------- */}
//               </div>
//               {/* ------ Right Part ------ */}
//               <div className={`grid col-span-1 xl:col-span-2 place-content-center xl:place-content-start `}>
//               <div className={isSticky ? 'sticky-wrapper' : ''}>
//                 <div className="flex flex-col justify-between bg-white rounded-xl shadow-md hover:shadow-lg cursor-pointer">
//                   {/* ------ Profile ------ */}
//                   <div className="flex flex-col justify-center items-center">
//                     <div
//                       className={`w-full grid place-items-center py-3 rounded-t-xl bg-[#E5002A]`}
//                     >
//                       <LazyLoadImage
//                         src={`${BACKEND_BASE_URL}${agentData?.agencyLogo}`}
//                         alt="icon"
//                         srcSet={`${BACKEND_BASE_URL}${agentData?.agencyLogo}`}
//                         loading="lazy"
//                         effect="blur"
//                         className="rounded-lg h-10"
//                       />
//                     </div>
//                     <div className="flex flex-col justify-center items-center pt-4 md:pt-6 px-4 md:px-6">
//                       <LazyLoadImage
//                         src={`${BACKEND_BASE_URL}${agentData?.profileImg}`}
//                         alt="icon"
//                         srcSet={`${BACKEND_BASE_URL}${agentData?.profileImg}`}
//                         loading="lazy"
//                         effect="blur"
//                         className="w-24 aspect-square rounded-full"
//                       />
//                       <div className="text-[#171717] text-center font-semibold text-sm md:text-lg lg:text-xl mt-4">
//                         {agentData?.name}
//                       </div>
//                       <div className="text-[#737373] text-center font-medium text-xs lg:text-sm mt-1">
//                         {/* Professionals Collective - Burleigh / Mudgeeraba */}
//                         {agentData?.agencyName}
//                       </div>

//                       {/* ------ Ratings ------ */}
//                       <div className="flex justify-center items-center mt-4">
//                         <Rating rating={agentData?.average} />
//                       </div>
//                       <div className="text-[#A3A3A3] text-center font-medium text-xs mt-1">
//                         {agentData?.average} ({agentData?.reviewCount} review)
//                       </div>

//                       {/* ------ Contact ------ */}
//                       <div className="grid grid-cols-1 gap-3 my-4 md:my-6">
//                         <div className="flex justify-start items-center gap-2 border border-[#F5F5F5] bg-white rounded-xl p-2">
//                           <img
//                             src={locationRed}
//                             alt="icon"
//                             className="bg-[#FFEAEF] p-3 rounded-lg"
//                           />
//                           <div className="flex flex-col justify-start items-center">
//                             <div className="text-[#3B8FD4] font-medium text-xs">
//                               {agentData?.agencyAddress}
//                             </div>
//                           </div>
//                         </div>

//                         <div className="flex justify-start items-center gap-2 border border-[#F5F5F5] bg-white rounded-xl p-2">
//                           <img
//                             src={callBlue}
//                             alt="icon"
//                             className="bg-[#E5F3FA] p-3 rounded-lg"
//                           />
//                           <div className="flex flex-col justify-start items-center">
//                             <div className="text-[#171717] font-semibold  text-xs">
//                               Phone No.
//                             </div>
//                             <div className="text-[#3B8FD4] font-medium text-center text-xs mt-1">
//                               <a
//                                 href={`tel:${agentData?.phone}`}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                               >
//                                 {agentData?.phone}
//                               </a>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   {/* ------ Buttons ------ */}
//                   <div className="w-full px-4 md:px-6 pb-4 md:pb-6">
//                     <button
//                       className="w-full text-xs md:text-sm lg:text-base hover:font-semibold font-medium border bg-[#E5002A] text-white hover:bg-white  hover:text-[#E5002A] hover:border-[#E5002A] py-3 px-5 rounded-3xl mt-3"
//                       onClick={() => navigate(`/appraisal/${agentData?.id}`)}
//                     >
//                       Get your property appraised
//                     </button>

//                     <button
//                       className="w-full text-xs md:text-sm lg:text-base font-semibold border text-[#737373] border-[#737373] py-3 px-5 rounded-3xl mt-3"
//                       onClick={() =>
//                         navigate(`/agent-profile/${agentData?.id}`)
//                       }
//                     >
//                       Get in touch
//                     </button>

//                     <button className="w-full flex justify-center items-center text-xs md:text-sm lg:text-base font-semibold border text-[#737373] border-[#737373] py-3 px-5 rounded-3xl mt-3">
//                       <img
//                         src={startGray}
//                         alt="icon"
//                         className="mr-2 cursor-pointer"
//                       />
//                       <div>Save property</div>
//                     </button>
//                   </div>
//                 </div>
//                 </div>
//               </div>
//             </div>
//             {/* ---------- End Section 2 -- -------- */}

//             {/* ||---------- Start Section 3 -- --------|| */}
//             <div className="grid grid-cols-1 xl:grid-cols-7 place-items-start gap-10 mb-10">
//               {/* ------ Left Part ------ */}
//               <div className="grid col-span-1 xl:col-span-5">
//                 {/* ------ Enquiry  ------ */}
//                 <div className="flex flex-col justify-between bg-white rounded-xl shadow-md hover:shadow-lg cursor-pointer">
//                   {/* ------ Heading ------ */}
//                   <div className="w-full flex flex-col justify-center items-start">
//                     <div
//                       className={`w-full grid place-items-center py-3 rounded-t-xl bg-[#E5002A]`}
//                     >
//                       <LazyLoadImage
//                         src={detailLogo}
//                         alt="icon"
//                         srcSet={detailLogo}
//                         loading="lazy"
//                         effect="blur"
//                         className="rounded-lg"
//                       />
//                     </div>
//                     {/* ------ Detail ------ */}

//                     <div className="w-full flex flex-col justify-start items-start pt-6 md:pt-12 px-4 md:px-6">
//                       <div className="text-[#171717] font-bold text-lg md:text-xl lg:text-2xl">
//                         Email enquiry to Profesionals Collective - Burleigh /
//                         Mudgeeraba
//                       </div>

//                       <div className="w-full flex flex-col md:flex-row justify-start items-center gap-4  md:gap-6 mt-4 md:mt-6">
//                         <LazyLoadImage
//                           src={`${BACKEND_BASE_URL}${PropertyData?.frontPageImg?.[0]}`}
//                           alt=""
//                           srcSet={`${BACKEND_BASE_URL}${PropertyData?.frontPageImg?.[0]}`}
//                           loading="lazy"
//                           effect="blur"
//                           className="w-auto md:w-96 rounded-lg"
//                         />
//                         <div className="w-full flex flex-col justify-start">
//                           <div className="text-[#171717] font-bold text-sm md:text-lg lg:text-xl">
//                             {PropertyData?.street_address_number},
//                             {PropertyData?.street_address_name},
//                             {PropertyData?.suburb}, {PropertyData?.municipality}
//                           </div>
//                           <div className="text-[#404040] font-bold text-xs md:text-sm lg:text-base mt-2">
//                             $ {PropertyData?.price_display}
//                           </div>
//                           <div className="flex flex-wrap justify-between items-start gap-4 mt-5 md:mt-5">
//                             <div className="flex justify-start items-center gap-2">
//                               <img
//                                 src={`${BACKEND_BASE_URL}${agentData?.profileImg}`}
//                                 alt="icon"
//                                 className="w-10 md:w-14 aspect-square rounded-full"
//                               />
//                               <div>
//                                 <div className="text-[#171717] font-semibold ext-xs md:text-sm lg:text-base xl:text-lg">
//                                   {agentData?.name}
//                                 </div>
//                                 <div className="flex justify-start items-center gap-1 text-[#A3A3A3] font-medium text-xs md:text-sm">
//                                   <svg
//                                     aria-hidden="true"
//                                     className="w-5 h-5 hover:text-gray-300 text-yellow-400"
//                                     fill="currentColor"
//                                     viewBox="0 0 20 20"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                   >
//                                     <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
//                                   </svg>
//                                   <div>5.0 (1 review)</div>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="flex justify-center items-center gap-2 bg-white border border-[#E5E5E5] cursor-pointer rounded-lg px-5 py-2">
//                               <LazyLoadImage
//                                 src={callBlue}
//                                 alt="icon"
//                                 srcSet={callBlue}
//                                 loading="lazy"
//                                 effect="blur"
//                                 className="w-4 cursor-pointer"
//                               />
//                               <div className="text-[#171717] font-medium text-xs md:text-sm">
//                                 {agentData?.phone}
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     {/* ------ Inputs ------ */}
//                     <div className="w-full flex flex-col justify-start items-start pt-6 md:pt-12 px-4 md:px-6">
//                       <div className="w-full">
//                         <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
//                           Message<span className="px-1 text-red-500">*</span>
//                         </div>
//                         <textarea
//                           rows="6"
//                           type="text"
//                           name="message"
//                           value={EnqiryData?.message}
//                           onChange={onChangeInput}
//                           placeholder="Type here...."
//                           className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
//                         />
//                       </div>

//                       <div className="w-full flex flex-col md:flex-row gap-4 mt-4 md:mt-6">
//                         <div className="w-full">
//                           <div className="w-full font-medium text-[#171717] text-xs md:text-sm lg:text-base">
//                             First name
//                             <span className="px-1 text-red-500">*</span>
//                           </div>
//                           <input
//                             type="text"
//                             name="first_name"
//                             value={EnqiryData?.first_name}
//                             onChange={onChangeInput}
//                             placeholder="Enter first name"
//                             className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
//                           />
//                         </div>
//                         <div className="w-full">
//                           <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
//                             Last name
//                             <span className="px-1 text-red-500">*</span>
//                           </div>
//                           <input
//                             type="text"
//                             name="last_name"
//                             value={EnqiryData?.last_name}
//                             onChange={onChangeInput}
//                             placeholder="Enter last name"
//                             className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
//                           />
//                         </div>
//                       </div>

//                       <div className="w-full flex flex-col md:flex-row gap-4 mt-4 md:mt-6">
//                         <div className="w-full">
//                           <div className="w-full font-medium text-[#171717] text-xs md:text-sm lg:text-base">
//                             Mobile phone number
//                             <span className="px-1 text-red-500">*</span>
//                           </div>
//                           <input
//                             type="number"
//                             name="mobile_no"
//                             value={EnqiryData?.mobile_no}
//                             onChange={onChangeInput}
//                             placeholder="Enter mobile phone number"
//                             className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
//                           />
//                         </div>
//                         <div className="w-full">
//                           <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
//                             Email <span className="px-1 text-red-500">*</span>
//                           </div>
//                           <input
//                             type="email"
//                             name="email"
//                             value={EnqiryData?.email}
//                             onChange={onChangeInput}
//                             placeholder="Enter email address"
//                             className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   {/* ------ Buttons ------ */}
//                   <div className="w-full px-4 md:px-6 pb-6 md:pb-12">
//                     <button
//                       className="w-full text-xs md:text-sm lg:text-base hover:font-semibold font-medium border bg-[#E5002A] text-white hover:bg-white  hover:text-[#E5002A] hover:border-[#E5002A] py-3 px-5 rounded-3xl mt-5 md:mt-8"
//                       onClick={SubmitReqest}
//                     >
//                       Send enquiry
//                     </button>
//                     <div className="text-[#171717] font-semibold text-xs md:text-sm lg:text-base underline px-2 mt-4 md:mt-6">
//                       Personal Information Collection Statement
//                     </div>
//                     <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base px-2 mt-2">
//                       This form is only to be used for sending genuine email
//                       enquiries to the Agent. realestate.com.au Pty Ltd reserves
//                       its right to take any legal or other appropriate action in
//                       relation to misuse of this service.
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               {/* ------ Right Part ------ */}
//               <div className="grid grid-cols-1 xl:col-span-2 place-content-center xl:place-content-start">
//                 <div className="flex flex-col sm:flex-row xl:flex-col justify-between items-center sm:items-start gap-6 xl:gap-12">
//                   {/* ------ News Card ------ */}
//                   <div className="flex flex-col justify-start items-start bg-white rounded-lg shadow-md hover:shadow-lg p-2">
//                     <LazyLoadImage
//                       src={news1}
//                       alt="icon"
//                       srcSet={news1}
//                       loading="lazy"
//                       effect="blur"
//                       className="rounded-lg"
//                     />
//                     <div className="text-[#404040] font-semibold text-sm md:text-base lg:text-lg mt-5 px-3">
//                       Professionals Collective - Burleigh / Mudgeeraba
//                     </div>
//                     <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base my-2 px-3">
//                       5/109 West Burleigh Road,, BURLEIGH WATERS, QLD 4220
//                     </div>
//                   </div>

//                   {/* ------ News Card ------ */}
//                   <div className="flex flex-col justify-start bg-white rounded-lg shadow-md hover:shadow-lg p-2">
//                     <LazyLoadImage
//                       src={news2}
//                       alt="icon"
//                       srcSet={news2}
//                       loading="lazy"
//                       effect="blur"
//                       className="w-full rounded-lg"
//                     />
//                     <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base mt-4 px-3">
//                       News: Victoria: Melbourne
//                     </div>
//                     <div className="text-[#404040] font-semibold text-sm md:text-base lg:text-lg mt-5 px-3">
//                       Service taken to new levels at boutique Armadale
//                       development
//                     </div>
//                     <div className="flex justify-start items-center text-[#E5002A] font-medium text-xs md:text-sm lg:text-base cursor-pointer my-5 px-3">
//                       Read More
//                       <LazyLoadImage
//                         src={sideArrowRed}
//                         alt="icon"
//                         srcSet={sideArrowRed}
//                         loading="lazy"
//                         effect="blur"
//                         className="ml-2"
//                       />
//                     </div>
//                     <div className="flex justify-end items-end">
//                       <img
//                         src={logoSmall}
//                         alt="logo"
//                         className="h-8 sm:h-10 mx-3 mb-3 rounded-xl  cursor-pointer"
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* ---------- End Section 3 -- -------- */}
//           </div>
//         </div>
//       </Layout2>

//       {/* <<----- Auth Pop Up ----->> */}

//       {AuthPopUp ? (
//         <>
//           <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999999]  outline-none focus:outline-none border ">
//             <div className="relative min-w-[250px] max-w-[90%] mx-auto  my-10 shadow-black shadow-2xl">
//               {/* ------ Content ------ */}
//               <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-white outline-none focus:outline-none">
//                 {/* ------ Header ------ */}
//                 <div className="grid place-items-center place-content-end">
//                   <button
//                     className="bg-transparent border-0 text-black opacity-9 text-2xl font-normal outline-none focus:outline-none mx-3 my-2"
//                     onClick={(e) => setAuthPopUp(false)}
//                   >
//                     ×
//                   </button>
//                 </div>
//                 {/* ------ Body ------ */}
//                 <div className="relative grid place-items-center px-6 md:px-10 py-3 flex-auto">
//                   <h3 className="text-black font-semibold text-base md:text-lg  leading-relaxed text-center">
//                     Sign in to save
//                   </h3>
//                   <p className="text-black font-medium text-xs md:text-sm  leading-normal text-center mt-5">
//                     Save properties to your account and sync across devices.
//                   </p>
//                 </div>

//                 {/* ------ Fotter ------ */}
//                 <div className="flex justify-center items-center m-5 mt-2">
//                   <button
//                     className="border-2 border-[#525252] hover:border-black bg-white text-[#525252] font-semibold text-sm px-7 py-3 rounded-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
//                     onClick={(e) => navigate("/sign-up")}
//                   >
//                     Join
//                   </button>
//                   <button
//                     className="border-2 border-[#E5002A] bg-[#E5002A] hover:bg-[#db183c] text-white font-semibold text-sm px-7 py-3 rounded-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
//                     onClick={(e) => navigate("/log-in")}
//                   >
//                     Sign in
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
//         </>
//       ) : null}
//     </>
//   );
// };

// export default DetailedViewProperty;

import React, { useEffect, useRef, useState } from "react";
import Layout2 from "../../Layouts/Layout2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import Googlemap from "../common/GoogleMap/Googlemap";
import agent01 from "../../assets/agent01.png";
import detailLogo from "../../assets/detailLogo.png";
import shareRed from "../../assets/shareRed.png";
import heart from "../../assets/emptyHeart.png";
import fullRedHeart from "../../assets/fillHeart.png";
import historyGray from "../../assets/historyGray.png";
import sideArrowRed from "../../assets/sideArrowRed.png";
import startGray from "../../assets/startGray.png";
import bedroom from "../../assets/bedroom_icon.png";
import car from "../../assets/car_icon.png";
import square from "../../assets/square.png";
import home from "../../assets/home.png";
import shower from "../../assets/shower_icon.png";
import shareBlue from "../../assets/shareBlue.png";
import estimateGray from "../../assets/estimateGray.png";
import map from "../../assets/map.png";
import callBlue from "../../assets/callBlue.png";
import locationRed from "../../assets/locationRed.png";
import news1 from "../../assets/news1.png";
import news2 from "../../assets/news2.png";
import logoSmall from "../../assets/logoSmall.png";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import { BACKEND_BASE_URL } from "../../apiInstances/baseurl";
import ImageGallery from "react-image-gallery";
import useOnClickOutside from "../../Hooks/useOnClickOutside/useOnClickOutside";
import { toast } from "react-toastify";
import isEmpty from "../common/utils/isEmpty";
import LimitedContentView from "../../components/common/LimitedContentView/LimitedContentView";
import { MdOutlineLiving } from "react-icons/md";
import { MdOutlineBathroom } from "react-icons/md";
import { GiMechanicGarage } from "react-icons/gi";
import { FaBath  } from "react-icons/fa";
import { MdBedroomParent } from "react-icons/md";
import { MdLocalParking } from "react-icons/md";
import { FaToilet } from "react-icons/fa";
const Rating = (props) => {
  const rating = props.rating;
  const stars = [];
  const fullStars = Math.floor(rating); // Number of full stars
  const hasHalfStar = rating - fullStars >= 0.5; // Check if there is a half star

  for (let i = 0; i < 5; i++) {
    if (i < fullStars) {
      stars.push(<i key={i} className="fa fa-star" />);
    } else if (i === fullStars && hasHalfStar) {
      stars.push(<i key={i} className="fa fa-star-half-o" />);
    } else {
      stars.push(<i key={i} className="fa fa-star-o" />);
    }
  }

  return <div className="rating text-yellow-400 gap-2 flex">{stars}</div>;
};

const DetailedViewProperty = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const { id } = useParams();

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [AuthPopUp, setAuthPopUp] = useState(false);

  const [GallaryView, setGallaryView] = useState(false);
  useOnClickOutside(ref, () => setGallaryView(false));
  const isAuthanticate = localStorage.getItem("Token");
  const isfavorite = async (i) => {
    await axiosInstanceAuth
      .post("addToFavorites", {
        id: i?._id,
      })
      .then((res) => {
        const mydata = res?.data?.data;
        if (res?.data?.status) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };
  const [PropertyData, setPropertyData] = useState({});
  const resPonce = isEmpty(PropertyData);
  const [PropertyImages, setPropertyImages] = useState([]);
  const [agentData, setAgentData] = useState({});
  const [EnqiryData, setEnqiryData] = useState({
    message: "",
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setEnqiryData({ ...EnqiryData, [name]: value });
  };

  useEffect(() => {
    ViewPropertyDatabyID(id);
  }, []);

  const ViewPropertyDatabyID = async (id) => {
    await axiosInstanceAuth
      .post(`Agency/viewProperty`, {
        id: id,
      })
      .then((res) => {
        const mydata = res?.data?.data;
        const myagentdata = res?.data?.agentData;

        if (res?.data?.status) {
          setPropertyData({
            ...mydata,
            mydata,
            favourite: localStorage
              .getItem("Saved")
              ?.split(",")
              .includes(mydata?._id),
          });
          setAgentData(myagentdata);

          const frontPageImg = mydata?.frontPageImg.map((obj, i) => ({
            original: `${BACKEND_BASE_URL}${obj}`,
          }));
          const propertyImg = mydata?.propertyImg.map((obj, i) => ({
            original: `${BACKEND_BASE_URL}${obj}`,
          }));
          const florePlansImg = mydata?.florePlansImg.map((obj, i) => ({
            original: `${BACKEND_BASE_URL}${obj}`,
          }));

          setPropertyImages([
            ...frontPageImg,
            ...propertyImg,
            ...florePlansImg,
          ]);
        } else {
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  const SubmitReqest = () => {
    try {
      if (EnqiryData?.message === "") {
        toast.error("Please enter a Message");
      } else if (EnqiryData?.first_name === "") {
        toast.error("Please enter a First Name");
      } else if (EnqiryData?.last_name === "") {
        toast.error("Please enter a Last Name");
      } else if (EnqiryData?.mobile_no === "") {
        toast.error("Please enter a Mobile Number");
      } else if (!emailRegex?.test(EnqiryData?.email)) {
        toast.error("Please enter a valid Email");
      } else {
        axiosInstanceAuth
          .post(`sendEnquiry`, {
            property_id: id,
            agent_id: agentData?.id,
            message: EnqiryData?.message,
            first_name: EnqiryData?.first_name,
            last_name: EnqiryData?.last_name,
            mobile_no: EnqiryData?.mobile_no,
            email: EnqiryData?.email,
          })
          .then((res) => {
            if (res?.data?.status) {
              toast.success("Enqiry Submited Successfuly");
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            console.log("------>> Error", err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const agentProfiles = useRef(null);
  const agencyInfo = useRef(null);

  return (
    <>
      <Layout2>
        <div className="px-5 pt-28">
          <div className="2xl:mx-80 lg:mx-40 xl:mx-60">
            {/* ||---------- Start Section 1 ----------|| */}
            <div className="">
              <div className="text-start text-[#171717] font-semibold text-xs md:text-sm">
                <span className="uppercase">
                  {PropertyData?.status === "active"
                    ? "buy"
                    : PropertyData?.status}
                </span>
                → {PropertyData?.municipality} → {PropertyData?.suburb} →
                {PropertyData?.street_address_name}
                {PropertyData?.street_address_number}
              </div>

              {/* ---------- Property Details Card ---------- */}
              <div className={`grid place-items-center my-5 md:my-10`}>
                <div className="flex flex-col bg-white rounded-lg shadow-md hover:shadow-lg p-4 md:p-6  md:w-full">
                  {/* ---------- Images ---------- */}

                  {GallaryView ? (
                    <div
                      ref={ref}
                      className={`w-full flex justify-center items-center gap-4`}
                    >
                      <ImageGallery items={PropertyImages} />
                    </div>
                  ) : (
                    <div
                      onClick={() => setGallaryView(true)}
                      className={`w-full  flex justify-center items-center gap-4`}
                    >
                      <LazyLoadImage
                        src={`${BACKEND_BASE_URL}${PropertyData?.frontPageImg?.[0]}`}
                        alt=""
                        srcSet={`${BACKEND_BASE_URL}${PropertyData?.frontPageImg?.[0]}`}
                        loading="lazy"
                        effect="blur"
                        style={{
                          width: "inherit !important",
                        }}
                        className="h-full md:h-[50vh] md:w-[65vw] rounded-lg cursor-pointer mb-2"
                      />
                      <div className="h-full hidden md:flex flex-col justify-center items-center gap-4 secondimg">
                        <LazyLoadImage
                          style={{ width: "inherit", objectFit: "cover" }}
                          src={`${BACKEND_BASE_URL}${PropertyData?.propertyImg?.[0]}`}
                          alt=""
                          srcSet={`${BACKEND_BASE_URL}${PropertyData?.propertyImg?.[0]}`}
                          loading="lazy"
                          effect="blur"
                          className="w-full md:h-[24.2vh] max-w-[150px] lg:max-w-[250px] rounded-lg cursor-pointer"
                        />
                        <div className="relative h-[50%] max-w-[150px] lg:max-w-[250px]">
                          <LazyLoadImage
                            style={{ width: "inherit" }}
                            src={`${BACKEND_BASE_URL}${PropertyData?.florePlansImg?.[0]}`}
                            alt=""
                            srcSet={`${BACKEND_BASE_URL}${PropertyData?.florePlansImg?.[0]}`}
                            loading="lazy"
                            effect="blur"
                            className="brightness-50 md:h-[24.2vh] w-full rounded-lg cursor-pointer"
                          />
                          <div className="h-full w-full md:h-[25vh] grid place-content-center text-white font-semibold text-4xl absolute inset-0 opacity-100 cursor-pointer">
                            {PropertyImages?.length > 3
                              ? `+${PropertyImages?.length - 3}`
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* ---------- Details ---------- */}
                  <div className="">
                    <div className="md:flex md:justify-between items-start gap-5 mt-5 md:mt-7">
                      <div className="text-[#171717] font-bold text-md md:text-xl lg:text-2xl xl:text-3xl">
                        {PropertyData?.street_address_number},
                        {PropertyData?.street_address_name},
                        {PropertyData?.suburb}, {PropertyData?.municipality}
                      </div>
                      <div className="flex items-center gap-2">
                        <LazyLoadImage
                          src={shareRed}
                          alt="icon"
                          srcSet={shareRed}
                          loading="lazy"
                          effect="blur"
                          className="bg-[#FFEAEF] border border-[#FA979A] rounded-full p-1 sm:p-2 md:p-3 cursor-pointer w-8 md:w-11"
                        />

                        {PropertyData?.favourite ? (
                          <LazyLoadImage
                            src={fullRedHeart}
                            alt="icon"
                            srcSet={fullRedHeart}
                            loading="lazy"
                            effect="blur"
                            onClick={(e) => {
                              if (!isAuthanticate) {
                                setAuthPopUp(true);
                              } else {
                                isfavorite(PropertyData);
                              }
                            }}
                            className="bg-[#FFEAEF] border border-[#FA979A] rounded-full p-1 sm:p-2 md:p-3 cursor-pointer w-8 md:w-11"
                          />
                        ) : (
                          <LazyLoadImage
                            src={heart}
                            alt="icon"
                            srcSet={heart}
                            loading="lazy"
                            effect="blur"
                            onClick={(e) => {
                              if (!isAuthanticate) {
                                setAuthPopUp(true);
                              } else {
                                isfavorite(PropertyData);
                              }
                            }}
                            className="bg-[#FFEAEF] border border-[#FA979A] rounded-full p-1 sm:p-2 md:p-3 cursor-pointer w-8 md:w-11"
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap justify-start items-center gap-2 mt-4">
                      <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                        <img
                          src={bedroom}
                          alt="location"
                          className="w-4 md:w-5 mr-3"
                        />
                        <div className="font-semibold text-[#737373] text-xs md:text-base">
                          {PropertyData?.Bedrooms}
                        </div>
                      </div>
                      <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                        <img
                          src={shower}
                          alt="bedroom"
                          className="w-4 md:w-5 mr-3"
                        />
                        <div className="font-semibold text-[#404040] text-xs md:text-sm">
                          {PropertyData?.Bathrooms}
                        </div>
                      </div>
                      <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                        <img
                          src={car}
                          alt="shower"
                          className="w-4 md:w-5 mr-3"
                        />
                        <div className="font-semibold text-[#404040] text-xs md:text-sm">
                          {PropertyData?.garage_spaces}
                        </div>
                      </div>
                      <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                        <img
                          src={square}
                          alt="car"
                          className="w-4 md:w-5 mr-3"
                        />
                        <div className="font-semibold text-[#404040] text-xs md:text-sm">
                          {PropertyData?.house_size_square}
                        </div>
                      </div>
                      <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                        <img src={home} alt="car" className="w-4 md:w-5 mr-3" />
                        <div className="font-semibold text-[#404040] text-xs md:text-sm">
                          {PropertyData?.house_size}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row justify-between items-start gap-3 mt-5 md:mt-7">
                      <div className="flex flex-col justify-start gap-2">
                        <div className="text-[#171717] font-semibold text-sm md:text-lg lg:text-xl">
                          $ {PropertyData?.price_display}
                        </div>
                        <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base">
                          Sold on 07 Jul 20222
                        </div>
                      </div>
                      <div className="flex justify-center items-center gap-2 bg-white border border-[#171717]  rounded-lg p-2 md:p-3">
                        <LazyLoadImage
                          src={historyGray}
                          alt="icon"
                          srcSet={historyGray}
                          loading="lazy"
                          effect="blur"
                          className="w-4 cursor-pointer"
                        />
                        <div className="text-[#171717] font-semibold text-xs md:text-sm lg:text-base">
                          Property History
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ---------- End Section 1 ---------- */}

            {/* ||---------- Start Section 2 ----------|| */}

            <div className="grid grid-cols-1 xl:grid-cols-7 place-content-start gap-5 mb-10">
              {/* ------ Left Part ------ */}
              <div className="grid col-span-1 xl:col-span-5">
                {/* ------ Cards ------ */}
                {/* <div className="w-full flex flex-col lg:flex-row justify-start gap-5">
                  <div
                    className={`w-auto lg:w-[50%] flex flex-col justify-start items-start bg-white rounded-2xl shadow-md hover:shadow-lg p-5`}
                  >
                    <div className="text-[#171717] font-semibold text-md md:text-xl">
                      Looking to buy a place like this?
                    </div>
                    <div className="w-full flex justify-start items-center mt-1">
                      <img
                        src={estimateGray}
                        alt="icon"
                        className="mr-2 cursor-pointer"
                      />
                      <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base underline">
                        Estimate repayments
                      </div>
                    </div>
                  </div>
                  <div
                    className={`w-auto lg:w-[50%] flex flex-col justify-start items-start bg-white rounded-2xl shadow-md hover:shadow-lg p-5`}
                  >
                    <div className="text-[#171717] font-semibold text-md md:text-xl">
                      Looking to sell a similar property?
                    </div>
                    <div className="w-full flex justify-between items-center gap-2 mt-1">
                      <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base underline">
                        See how the selling agwes
                      </div>
                      <img
                        src={shareBlue}
                        alt="icon"
                        className="p-1 cursor-pointer"
                      />
                    </div>
                  </div>
                </div> */}
                {/* ------ Google Map ------ */}
                <div className="flex flex-col bg-white rounded-lg shadow-md hover:shadow-lg p-4  ">
                  {/* <LazyLoadImage
                  src={map}
                  alt="icon"
                  srcSet={map}
                  loading="lazy"
                  effect="blur"
                  className="cursor-pointer"
                /> */}
                  <div className="custStyle !relative h-[50vh] rounded-md">
                    {resPonce ? (
                      ""
                    ) : (
                      <Googlemap
                        id={id}
                        location={PropertyData?.location?.coordinates}
                      />
                    )}
                  </div>
                  <div className="md:px-4">
                    <div className="text-[#171717] font-bold text-lg md:text-xl lg:text-xl mt-4 md:mt-6 ">
                      Sold By Paul Donahoe, Professionals Collective
                    </div>
                    <div className="text-[#737373] font-semibold text-sm md:text-base lg:text-lg mt-2 ">
                      {PropertyData?.street_address_number}
                      {PropertyData?.street_address_name},{" "}
                      {PropertyData?.suburb}
                    </div>
                    <div className="text-[#525252] font-medium text-xs md:text-sm lg:text-base mt-4 md:mt-6 ">
                      {PropertyData?.heading}
                    </div>
                    <div className="text-[#525252] font-medium text-xs md:text-sm lg:text-base mt-3 ">
                      <LimitedContentView content={PropertyData?.discription} />
                    </div>

                    {/* <div className="flex justify-start items-center text-[#E5002A] font-medium text-xs md:text-sm lg:text-base cursor-pointer my-4 md:my-8">
                      Read More 
                      <LazyLoadImage
                        src={sideArrowRed}
                        alt="icon"
                        srcSet={sideArrowRed}
                        loading="lazy"
                        effect="blur"
                        className="ml-2"
                      />
                    </div> */}
                  </div>
                </div>

                {/* ------------inspection start---------------- */}
                {/* -------Property features-------- */}
                <div
                  className={`w-auto flex flex-col justify-start items-start bg-white rounded-2xl shadow-md hover:shadow-lg p-5 mt-10`}
                >
                  <div className="text-[#171717] font-semibold text-md md:text-xl">
                    Property features
                  </div>
                  <div className="w-full grid grid-cols-2 gap-2 mt-1">
                    <div className="flex items-center gap-2">
                      <div>
                        <MdOutlineLiving />
                      </div>
                      <div>living areas : {PropertyData?.living_areas}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                      {/* <PiToiletLight />  */}
                      <FaToilet />                                        </div>
                     <div>toilets : {PropertyData?.toilets}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                      <FaBath />
                      </div>
                      <div>Bathrooms : {PropertyData?.Bathrooms}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                      <MdBedroomParent />
                      </div>
                      <div>Bedrooms : {PropertyData?.Bedrooms}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                      <MdOutlineBathroom />
                      </div>
                      <div>Ensuites : {PropertyData?.Ensuites}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                      <MdLocalParking />
                                            </div>
                      <div>Carport spaces : {PropertyData?.carport_spaces}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                      <GiMechanicGarage />
                      </div>
                      <div>Garage spaces : {PropertyData?.garage_spaces}</div>
                    </div>

                   
                  </div>
                </div>
                <div
                  className={`w-auto flex flex-col justify-start items-start bg-white rounded-2xl shadow-md hover:shadow-lg p-5 mt-10`}
                >
                  <div className="text-[#171717] font-semibold text-md md:text-xl">
                    Inspections
                  </div>
                  <div
                    className="w-full flex justify-between items-center gap-2 mt-1"
                    onClick={() => navigate(`/inspection`)}
                  >
                    <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base underline cursor-pointer">
                      There are no upcoming inspections for this property.
                      Contact the agent to see this place.
                    </div>
                    <img
                      src={shareBlue}
                      alt="icon"
                      className="p-1 cursor-pointer"
                    />
                  </div>
                </div>
                {/* ------------inspection end---------------- */}
              </div>
              {/* ------ Right Part ------ */}
              <div
                className={`grid col-span-1 xl:col-span-2 place-content-center xl:place-content-start `}
              >
                <div
                  className="flex flex-col justify-between bg-white rounded-xl shadow-md hover:shadow-lg cursor-pointer sticky top-20    "
                  ref={agentProfiles}
                >
                  {/* ------ Profile ------ */}
                  <div className="flex flex-col justify-center items-center">
                    <div
                      className={`w-full grid place-items-center py-3 rounded-t-xl bg-[${agentData.primary_color}]`}
                    >
                      <LazyLoadImage
                        src={`${BACKEND_BASE_URL}${agentData?.agencyLogo}`}
                        alt="icon"
                        srcSet={`${BACKEND_BASE_URL}${agentData?.agencyLogo}`}
                        loading="lazy"
                        effect="blur"
                        className="rounded-lg h-10"
                      />
                    </div>
                    <div className="flex flex-col justify-center items-center pt-4 md:pt-6 px-4 md:px-6">
                      <LazyLoadImage
                        src={`${BACKEND_BASE_URL}${agentData?.profileImg}`}
                        alt="icon"
                        srcSet={`${BACKEND_BASE_URL}${agentData?.profileImg}`}
                        loading="lazy"
                        effect="blur"
                        className="w-24 aspect-square rounded-full"
                      />
                      <div className="text-[#171717] text-center font-semibold text-sm md:text-lg lg:text-xl mt-4">
                        {agentData?.name}
                      </div>
                      <div className="text-[#737373] text-center font-medium text-xs lg:text-sm mt-1">
                        {/* Professionals Collective - Burleigh / Mudgeeraba */}
                        {agentData?.agencyName}
                      </div>

                      {/* ------ Ratings ------ */}
                      <div className="flex justify-center items-center mt-4">
                        <Rating rating={agentData?.average} />
                      </div>
                      <div className="text-[#A3A3A3] text-center font-medium text-xs mt-1">
                        {agentData.average == 0 || agentData.average == null
                          ? agentData?.average
                          : agentData?.average.toFixed(1)}{" "}
                        ({agentData?.reviewCount} review)
                      </div>

                      {/* ------ Contact ------ */}
                      <div className="grid grid-cols-1 gap-3 my-4 md:my-6">
                        <div className="flex justify-start items-center gap-2 border border-[#F5F5F5] bg-white rounded-xl p-2">
                          <img
                            src={locationRed}
                            alt="icon"
                            className="bg-[#FFEAEF] p-3 rounded-lg"
                          />
                          <div className="flex flex-col justify-start items-center">
                            <div className="text-[#3B8FD4] font-medium text-xs">
                              {agentData?.agencyAddress}
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-start items-center gap-2 border border-[#F5F5F5] bg-white rounded-xl p-2">
                          <img
                            src={callBlue}
                            alt="icon"
                            className="bg-[#E5F3FA] p-3 rounded-lg"
                          />
                          <div className="flex flex-col justify-start items-center">
                            <div className="text-[#171717] font-semibold  text-xs">
                              Phone No.
                            </div>
                            <div className="text-[#3B8FD4] font-medium text-center text-xs mt-1">
                              <a
                                href={`tel:${agentData?.phone}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {agentData?.phone}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ------ Buttons ------ */}
                  <div className="w-full px-4 md:px-6 pb-4 md:pb-6">
                    <button
                      className="w-full text-xs md:text-sm lg:text-base hover:font-semibold font-medium border bg-[#E5002A] text-white hover:bg-white  hover:text-[#E5002A] hover:border-[#E5002A] py-3 px-5 rounded-3xl mt-3"
                      onClick={() => navigate(`/appraisal/${agentData?.id}`)}
                    >
                      Get your property appraised
                    </button>

                    <button
                      className="w-full text-xs md:text-sm lg:text-base font-semibold border text-[#737373] border-[#737373] py-3 px-5 rounded-3xl mt-3"
                      onClick={() =>
                        navigate(`/agent-profile/${agentData?.id}`)
                      }
                    >
                      Get in touch
                    </button>

                    <button className="w-full flex justify-center items-center text-xs md:text-sm lg:text-base font-semibold border text-[#737373] border-[#737373] py-3 px-5 rounded-3xl mt-3">
                      <img
                        src={startGray}
                        alt="icon"
                        className="mr-2 cursor-pointer"
                      />
                      <div>Save property</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ---------- End Section 2 -- -------- */}

            {/* ||---------- Start Section 3 -- --------|| */}
            <div className="grid grid-cols-1 xl:grid-cols-7 place-items-start gap-10 mb-10">
              {/* ------ Left Part ------ */}
              <div className="grid col-span-1 xl:col-span-5">
                {/* ------ Enquiry  ------ */}
                <div className="flex flex-col justify-between bg-white rounded-xl shadow-md hover:shadow-lg cursor-pointer">
                  {/* ------ Heading ------ */}
                  <div className="w-full flex flex-col justify-center items-start">
                    <div
                      className={`w-full grid place-items-center py-3 rounded-t-xl bg-[${agentData.primary_color}]`}
                    >
                      <LazyLoadImage
                        src={`${BACKEND_BASE_URL}${agentData?.agencyLogo}`}
                        alt="icon"
                        srcSet={`${BACKEND_BASE_URL}${agentData?.agencyLogo}`}
                        loading="lazy"
                        effect="blur"
                        className="rounded-lg h-10"
                      />
                    </div>
                    {/* ------ Detail ------ */}

                    <div className="w-full flex flex-col justify-start items-start pt-6 md:pt-12 px-4 md:px-6">
                      <div className="text-[#171717] font-bold text-lg md:text-xl lg:text-2xl">
                        Email enquiry to Profesionals Collective - Burleigh /
                        Mudgeeraba
                      </div>

                      <div className="w-full flex flex-col md:flex-row justify-start items-center gap-4  md:gap-6 mt-4 md:mt-6">
                        <LazyLoadImage
                          src={`${BACKEND_BASE_URL}${PropertyData?.frontPageImg?.[0]}`}
                          alt=""
                          srcSet={`${BACKEND_BASE_URL}${PropertyData?.frontPageImg?.[0]}`}
                          loading="lazy"
                          effect="blur"
                          className="w-auto md:w-96 rounded-lg"
                        />
                        <div className="w-full flex flex-col justify-start">
                          <div className="text-[#171717] font-bold text-sm md:text-lg lg:text-xl">
                            {PropertyData?.street_address_number},
                            {PropertyData?.street_address_name},
                            {PropertyData?.suburb}, {PropertyData?.municipality}
                          </div>
                          <div className="text-[#404040] font-bold text-xs md:text-sm lg:text-base mt-2">
                            $ {PropertyData?.price_display}
                          </div>
                          <div className="flex flex-wrap justify-between items-start gap-4 mt-5 md:mt-5">
                            <div className="flex justify-start items-center gap-2">
                              <img
                                src={`${BACKEND_BASE_URL}${agentData?.profileImg}`}
                                alt="icon"
                                className="w-10 md:w-14 aspect-square rounded-full"
                              />
                              <div>
                                <div className="text-[#171717] font-semibold ext-xs md:text-sm lg:text-base xl:text-lg">
                                  {agentData?.name}
                                </div>
                                <Rating rating={agentData?.average} />
                                <span className="text-[#A3A3A3] text-sm">
                                  {agentData.average == 0 ||
                                  agentData.average == null
                                    ? agentData?.average
                                    : agentData?.average.toFixed(1)}{" "}
                                  ({agentData?.reviewCount} review)
                                </span>
                              </div>
                            </div>
                            <div className="flex justify-center items-center gap-2 bg-white border border-[#E5E5E5] cursor-pointer rounded-lg px-5 py-2">
                              <LazyLoadImage
                                src={callBlue}
                                alt="icon"
                                srcSet={callBlue}
                                loading="lazy"
                                effect="blur"
                                className="w-4 cursor-pointer"
                              />
                              <div className="text-[#171717] font-medium text-xs md:text-sm">
                                {agentData?.phone}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ------ Inputs ------ */}
                    <div className="w-full flex flex-col justify-start items-start pt-6 md:pt-12 px-4 md:px-6">
                      <div className="w-full">
                        <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
                          Message<span className="px-1 text-red-500">*</span>
                        </div>
                        <textarea
                          rows="6"
                          type="text"
                          name="message"
                          value={EnqiryData?.message}
                          onChange={onChangeInput}
                          placeholder="Type here...."
                          className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
                        />
                      </div>

                      <div className="w-full flex flex-col md:flex-row gap-4 mt-4 md:mt-6">
                        <div className="w-full">
                          <div className="w-full font-medium text-[#171717] text-xs md:text-sm lg:text-base">
                            First name
                            <span className="px-1 text-red-500">*</span>
                          </div>
                          <input
                            type="text"
                            name="first_name"
                            value={EnqiryData?.first_name}
                            onChange={onChangeInput}
                            placeholder="Enter first name"
                            className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
                          />
                        </div>
                        <div className="w-full">
                          <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
                            Last name
                            <span className="px-1 text-red-500">*</span>
                          </div>
                          <input
                            type="text"
                            name="last_name"
                            value={EnqiryData?.last_name}
                            onChange={onChangeInput}
                            placeholder="Enter last name"
                            className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
                          />
                        </div>
                      </div>

                      <div className="w-full flex flex-col md:flex-row gap-4 mt-4 md:mt-6">
                        <div className="w-full">
                          <div className="w-full font-medium text-[#171717] text-xs md:text-sm lg:text-base">
                            Mobile phone number
                            <span className="px-1 text-red-500">*</span>
                          </div>
                          <input
                            type="number"
                            name="mobile_no"
                            value={EnqiryData?.mobile_no}
                            onChange={onChangeInput}
                            placeholder="Enter mobile phone number"
                            className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
                          />
                        </div>
                        <div className="w-full">
                          <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
                            Email <span className="px-1 text-red-500">*</span>
                          </div>
                          <input
                            type="email"
                            name="email"
                            value={EnqiryData?.email}
                            onChange={onChangeInput}
                            placeholder="Enter email address"
                            className="w-full font-medium text-[#737373] text-xs md:text-sm lg:text-base outline-none border border-[#E5E5E5] rounded-3xl py-3 px-5  mt-2 md:mt-4"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ------ Buttons ------ */}
                  <div className="w-full px-4 md:px-6 pb-6 md:pb-12">
                    <button
                      className="w-full text-xs md:text-sm lg:text-base hover:font-semibold font-medium border bg-[#E5002A] text-white hover:bg-white  hover:text-[#E5002A] hover:border-[#E5002A] py-3 px-5 rounded-3xl mt-5 md:mt-8"
                      onClick={SubmitReqest}
                    >
                      Send enquiry
                    </button>
                    <div className="text-[#171717] font-semibold text-xs md:text-sm lg:text-base underline px-2 mt-4 md:mt-6">
                      Personal Information Collection Statement
                    </div>
                    <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base px-2 mt-2">
                      This form is only to be used for sending genuine email
                      enquiries to the Agent. realestate.com.au Pty Ltd reserves
                      its right to take any legal or other appropriate action in
                      relation to misuse of this service.
                    </div>
                  </div>
                </div>
              </div>
              {/* ------ Right Part ------ */}
              <div
                ref={agencyInfo}
                className="sticky top-20 w-full xl:col-span-2 place-content-center xl:place-content-start"
              >
                <div className="grid grid-cols-1   ">
                  <div className="flex flex-col sm:flex-row xl:flex-col justify-between items-center sm:items-start gap-6 xl:gap-12 ">
                    {/* ------ News Card ------ */}
                    <div className="flex flex-col justify-start items-start bg-white rounded-lg shadow-md hover:shadow-lg p-2 ">
                      <LazyLoadImage
                        src={news1}
                        alt="icon"
                        srcSet={news1}
                        loading="lazy"
                        effect="blur"
                        className="rounded-lg"
                      />
                      <div className="text-[#404040] font-semibold text-sm md:text-base lg:text-lg mt-5 px-3">
                        Professionals Collective - Burleigh / Mudgeeraba
                      </div>
                      <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base my-2 px-3">
                        5/109 West Burleigh Road,, BURLEIGH WATERS, QLD 4220
                      </div>
                    </div>

                    {/* ------ News Card ------ */}
                    <div className="flex flex-col justify-start bg-white rounded-lg shadow-md hover:shadow-lg p-2">
                      <LazyLoadImage
                        src={news2}
                        alt="icon"
                        srcSet={news2}
                        loading="lazy"
                        effect="blur"
                        className="w-full rounded-lg"
                      />
                      <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base mt-4 px-3">
                        News: Victoria: Melbourne
                      </div>
                      <div className="text-[#404040] font-semibold text-sm md:text-base lg:text-lg mt-5 px-3">
                        Service taken to new levels at boutique Armadale
                        development
                      </div>
                      <div className="flex justify-start items-center text-[#E5002A] font-medium text-xs md:text-sm lg:text-base cursor-pointer my-5 px-3">
                        Read More
                        <LazyLoadImage
                          src={sideArrowRed}
                          alt="icon"
                          srcSet={sideArrowRed}
                          loading="lazy"
                          effect="blur"
                          className="ml-2"
                        />
                      </div>
                      <div className="flex justify-end items-end">
                        <img
                          src={logoSmall}
                          alt="logo"
                          className="h-8 sm:h-10 mx-3 mb-3 rounded-xl  cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ---------- End Section 3 -- -------- */}
          </div>
        </div>
      </Layout2>

      {/* <<----- Auth Pop Up ----->> */}

      {AuthPopUp ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[999999]  outline-none focus:outline-none border ">
            <div className="relative min-w-[250px] max-w-[90%] mx-auto  my-10 shadow-black shadow-2xl">
              {/* ------ Content ------ */}
              <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* ------ Header ------ */}
                <div className="grid place-items-center place-content-end">
                  <button
                    className="bg-transparent border-0 text-black opacity-9 text-2xl font-normal outline-none focus:outline-none mx-3 my-2"
                    onClick={(e) => setAuthPopUp(false)}
                  >
                    ×
                  </button>
                </div>
                {/* ------ Body ------ */}
                <div className="relative grid place-items-center px-6 md:px-10 py-3 flex-auto">
                  <h3 className="text-black font-semibold text-base md:text-lg  leading-relaxed text-center">
                    Sign in to save
                  </h3>
                  <p className="text-black font-medium text-xs md:text-sm  leading-normal text-center mt-5">
                    Save properties to your account and sync across devices.
                  </p>
                </div>

                {/* ------ Fotter ------ */}
                <div className="flex justify-center items-center m-5 mt-2">
                  <button
                    className="border-2 border-[#525252] hover:border-black bg-white text-[#525252] font-semibold text-sm px-7 py-3 rounded-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    onClick={(e) => navigate("/sign-up")}
                  >
                    Join
                  </button>
                  <button
                    className="border-2 border-[#E5002A] bg-[#E5002A] hover:bg-[#db183c] text-white font-semibold text-sm px-7 py-3 rounded-lg outline-none focus:outline-none ease-linear transition-all duration-150 mx-2"
                    onClick={(e) => navigate("/log-in")}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default DetailedViewProperty;
