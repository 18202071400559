import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import showcaseAgency from "../../../assets/showcase-agency.png";
import Image0 from "../../../assets/image (0).png";
import Image2 from "../../../assets/Image (2).png";
import Image3 from "../../../assets/Image (3).png";
import Image4 from "../../../assets/Image (4).png";
import showcaseAgent from "../../../assets/showcase-agent.png";
import bedroom_icon from "../../../assets/bedroom_icon.png";
import shower_icon from "../../../assets/shower_icon.png";
import car_icon from "../../../assets/car_icon.png";
import square from "../../../assets/square.png";

const PropertyShowcaseCard = () => {
  return (
    <div className="grid place-items-center mx-auto">
      <div className="text-start font-semibold text-sm lg:text-lg text-[#737373] py-4 px-5">
        PROPERTY SHOWCASE
      </div>
      <div className="max-w-lg grid place-items-center bg-white rounded-2xl shadow-md hover:shadow-lg">
        <img src={showcaseAgency} alt="" className="rounded-t-2xl" />

        <div className="p-5">
          <div className="flex flex-col lg:flex-row justify-center">
            <div className="lg:w-72 grid grid-cols-1 gap-2 max-w-72 mr-0 lg:mr-4 mb-4 lg:mb-0">
              <LazyLoadImage
                src={Image0}
                alt="icon"
                srcSet={Image0}
                loading="lazy"
                effect="blur"
                className="rounded-md"
              />
              {/* <img src={Image0} alt="icon" className="rounded-md" /> */}
            </div>
            <div className="grid grid-cols-6 lg:grid-cols-2 gap-3">
              <LazyLoadImage
                src={Image2}
                alt="icon"
                srcSet={Image0}
                loading="lazy"
                effect="blur"
                className="rounded-md"
              />
              <LazyLoadImage
                src={Image2}
                alt="icon"
                srcSet={Image0}
                loading="lazy"
                effect="blur"
                className="rounded-md"
              />
              <LazyLoadImage
                src={Image3}
                alt="icon"
                srcSet={Image3}
                loading="lazy"
                effect="blur"
                className="rounded-md"
              />
              <LazyLoadImage
                src={Image3}
                alt="icon"
                srcSet={Image3}
                loading="lazy"
                effect="blur"
                className="rounded-md"
              />
              <LazyLoadImage
                src={Image4}
                alt="icon"
                srcSet={Image4}
                loading="lazy"
                effect="blur"
                className="rounded-md"
              />
              <LazyLoadImage
                src={Image4}
                alt="icon"
                srcSet={Image4}
                loading="lazy"
                effect="blur"
                className="rounded-md"
              />
              {/* <img src={Image2} alt="icon" className="rounded-md" />
              <img src={Image2} alt="icon" className="rounded-md" />
              <img src={Image3} alt="icon" className="rounded-md" />
              <img src={Image3} alt="icon" className="rounded-md" />
              <img src={Image4} alt="icon" className="rounded-md" />
              <img src={Image4} alt="icon" className="rounded-md" /> */}
            </div>
          </div>
          <div className="mx-4">
            <div className="font-extrabold text-[#171717] text-start text-md lg:text-xl mt-4 lg:mt-5">
              27/7 Giosam Street, Richlands
            </div>

            <div className="flex flex-wrap justify-start items-center gap-2 mt-3">
              <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                <img
                  src={bedroom_icon}
                  alt="location"
                  className="w-4 lg:w-5 mr-3"
                />
                <div className="font-semibold text-[#737373] text-xs lg:text-base">
                  1
                </div>
              </div>
              <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                <img
                  src={shower_icon}
                  alt="bedroom"
                  className="w-4 lg:w-5 mr-3"
                />
                <div className="font-semibold text-[#737373] text-xs lg:text-base">
                  3
                </div>
              </div>
              <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                <img src={car_icon} alt="shower" className="w-4 lg:w-5 mr-3" />
                <div className="font-semibold text-[#737373] text-xs lg:text-base">
                  2
                </div>
              </div>
              <div className="flex justify-center items-center bg-[#F5F5F5] rounded-md p-2">
                <img src={square} alt="car" className="w-4 lg:w-5 mr-3" />
                <div className="font-semibold text-[#737373] text-xs lg:text-base">
                  155 m2
                </div>
              </div>
            </div>

            <div className="flex justify-start items-center mt-3">
              {/* <img
                src={showcaseAgent}
                alt="bedroom"
                className="w-10 lg:w-14 rounded-full mr-3"
              /> */}
              <LazyLoadImage
                src={showcaseAgent}
                alt="icon"
                srcSet={showcaseAgent}
                loading="lazy"
                effect="blur"
                className="w-10 lg:w-14 rounded-full mr-3"
              />
              <div className="font-semibold text-[#171717] text-sm lg:text-lg">
                Brendan Lewington
              </div>
            </div>

            <div className="w-full grid place-items-center border border-[#737373] rounded-3xl mt-3 cursor-pointer">
              <div className="font-semibold text-[#737373] text-sm lg:text-lg py-2">
                View Property
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyShowcaseCard;
