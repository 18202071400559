// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import Logo from "../assets/logo.png";
// import useEncryption from "../useEncryption/useEncryption";
// import axiosInstance from "../apiInstances/axiosInstance";

// const LogInForm = () => {
//   const navigate = useNavigate();
//   const { decryptData } = useEncryption();

//   useEffect(() => {
//     const checkAuth = localStorage.getItem("Token");

//     if (checkAuth === undefined || checkAuth === null) {
//     } else {
//       navigate("/");
//     }
//   }, []);

//   const [fields, setFields] = useState({
//     email: "",
//     password: "",
//   });

//   const onChangeInput = (e) => {
//     const value = e.target.value.replace(/^\s+|\s+$/gm, "");
//     const name = e.target.name;

//     setFields({
//       ...fields,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async () => {
//     const encryptedData = JSON.stringify({
//       email: fields?.email,
//       password: fields?.password,
//       role: "user",
//     });
//     await axiosInstance
//       .post("signin", encryptedData)
//       .then((res) => {
//         if (res?.data?.status) {
//           localStorage.setItem("Token", res?.data?.data?.token);
//           navigate("/dashbord");
//           toast.success(res?.data?.message);
//         } else {
//           if (res?.status === true) {
//             navigate("/VerifyOtp");
//           }
//           toast.error(res?.data?.message);
//         }
//       })
//       .catch((err) => {
//         console.log("err --->", err);
//       });
//   };

//   const InputBox =
//     "w-full md:w-[445px] text-xs md:text-sm rounded-lg shadow-[0_4px_4px_rgba(0,0,0,0.25)] my-3 md:my-5 py-5 px-8 outline-none";

//   const OuterBox =
//     "min-w-[40%] rounded-2xl shadow-[0_0_4px_rgba(0,0,0,0.25)] bg-white p-5";

//   return (
//     <>
//       <section className="mx-auto px-5 h-screen">
//         <div className="h-full grid place-items-center">
//           <div
//             className={`flex flex-col justify-center items-center text-gray-800 ${OuterBox}`}
//           >
//             <div className="max-w-xs">
//               <img
//                 src={Logo}
//                 alt="logo"
//                 className="cursor-pointer"
//                 onClick={() => {
//                   navigate("/");
//                 }}
//               />
//               {/* <h1
//                 className="bg-[#FFCCD3] rounded-lg text-[#FF444D] font-extrabold text-2xl md:text-3xl px-5 py-2 my-5 cursor-pointer"
//                 onClick={() => {
//                   navigate("/");
//                 }}
//               >
//                 La Rosa
//               </h1> */}
//             </div>
//             <div className="max-w-md">
//               {/* ---- Email input ---- */}

//               <input
//                 type="email"
//                 className={`${InputBox}`}
//                 placeholder="Email"
//                 name="email"
//                 value={fields?.email}
//                 onChange={onChangeInput}
//                 onKeyDown={(e) => {
//                   if (e.key === "Enter") {
//                     handleSubmit();
//                   }
//                 }}
//               />

//               {/* ---- Password input ---- */}

//               <input
//                 type="password"
//                 className={`${InputBox}`}
//                 placeholder="Password"
//                 name="password"
//                 value={fields?.password}
//                 onChange={onChangeInput}
//                 onKeyDown={(e) => {
//                   if (e.key === "Enter") {
//                     handleSubmit();
//                   }
//                 }}
//               />

//               {/* ---- Forgot Password ---- */}

//               <div className="flex justify-end items-center">
//                 <button
//                   className="text-xs md:text-sm text-gray-500 hover:text-gray-900 mx-3"
//                   onClick={() => navigate("/auth/forgot-password")}
//                 >
//                   Forgot Password
//                 </button>
//               </div>

//               {/* ---- Submit button ---- */}

//               <div className="flex justify-center items-center">
//                 <button
//                   className="my-5 py-3 px-8 rounded-lg bg-[#E5002A] hover:bg-[#E5002A] text-white text-sm md:text-base font-medium"
//                   onClick={handleSubmit}
//                 >
//                   Log in
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default LogInForm;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../assets/logo.png";
import Google from "../assets/google.png";
import Facebook from "../assets/fac.png";
import useEncryption from "../useEncryption/useEncryption";
import axiosInstance from "../apiInstances/axiosInstance";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from "axios";

const LogInForm = () => {
  const navigate = useNavigate();
  const { decryptData } = useEncryption();
  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState([]);
  console.log("🚀 ~ LogInForm ~ profile:", profile)

  useEffect(() => {
    const checkAuth = localStorage.getItem("Token");

    if (checkAuth === undefined || checkAuth === null) {
    } else {
      navigate("/");
    }
  }, []);

  const [fields, setFields] = useState({
    email: "",
    password: "",
  });

  const onChangeInput = (e) => {
    const value = e.target.value.replace(/^\s+|\s+$/gm, "");
    const name = e.target.name;

    setFields({
      ...fields,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const encryptedData = JSON.stringify({
      email: fields?.email,
      password: fields?.password,
      role: "user",
    });
    await axiosInstance
      .post("signin", encryptedData)
      .then((res) => {
        if (res?.data?.status) {
          localStorage.setItem("Token", res?.data?.data?.token);
          navigate("/dashbord");
          toast.success(res?.data?.message);
        } else {
          if (res?.status === true) {
            navigate("/VerifyOtp");
          }
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };


  // google login

  const GoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
});
useEffect(
  () => {
      if (user) {
          axios
              .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                  headers: {
                      Authorization: `Bearer ${user.access_token}`,
                      Accept: 'application/json'
                  }
              })
              .then((res) => {
                if(res.status==200)
                {
                  setProfile(res.data);
                  navigate("/dashbord");
                }
              
              })
              .catch((err) => console.log(err));
      }
  },
  [ user ]
);

  const InputBox =
    "w-full md:w-[445px] text-xs md:text-sm rounded-lg shadow-[0_4px_4px_rgba(0,0,0,0.25)] my-3 md:my-5 py-5 px-8 outline-none";

  const OuterBox =
    "min-w-[40%] rounded-2xl shadow-[0_0_4px_rgba(0,0,0,0.25)] bg-white p-5";

  return (
    <>
      <section className="mx-auto px-5 h-screen">
        <div className="h-full grid place-items-center">
          <div
            className={`flex flex-col justify-center items-center text-gray-800 ${OuterBox}`}
          >
            <div className="max-w-xs">
              <img
                src={Logo}
                alt="logo"
                className="cursor-pointer"
                onClick={() => {
                  navigate("/");
                }}
              />
              {/* <h1
                className="bg-[#FFCCD3] rounded-lg text-[#FF444D] font-extrabold text-2xl md:text-3xl px-5 py-2 my-5 cursor-pointer"
                onClick={() => {
                  navigate("/");
                }}
              >
                La Rosa
              </h1> */}
            </div>
            <div className="max-w-md">
              {/* ---- Email input ---- */}

              <input
                type="email"
                className={`${InputBox}`}
                placeholder="Email"
                name="email"
                value={fields?.email}
                onChange={onChangeInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />

              {/* ---- Password input ---- */}

              <input
                type="password"
                className={`${InputBox}`}
                placeholder="Password"
                name="password"
                value={fields?.password}
                onChange={onChangeInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />

              {/* ---- Submit button ---- */}

              <div className="flex justify-center items-center">
                <button
                  className="my-5 py-3 px-8 rounded-lg bg-[#E5002A] hover:bg-[#E5002A] text-white text-sm md:text-base font-medium w-full"
                  onClick={handleSubmit}
                >
                  Log in
                </button>
              </div>

              {/* ---- Forgot Password ---- */}

              <div className="flex justify-center items-center">
                <button
                  className="text-xs md:text-xl text-gray-500 hover:text-gray-900 mx-3"
                  onClick={() => navigate("/auth/forgot-password")}
                >
                  Forgot Password
                </button>
              </div>

              <hr className="mt-5" />

              {/* ---- Google button ---- */}

              <div className="flex flex-col  gap-5 ">
                <div className="flex justify-center items-center border border-black  rounded-lg ">
                  <img src={Google} alt="google" className="pl-5 w-14" />
                  <button
                    className="p-5 px-8  text-black text-sm md:text-base font-medium w-full"
                    onClick={GoogleLogin}
                  >
                    Continue with Google
                  </button>
                </div>

                <div className="flex justify-center items-center bg-[#4267B2] border border-black  rounded-lg">
                  <img src={Facebook} alt="google" className="pl-5 w-14" />
                  <button
                    className="p-5 px-8  text-black  text-sm md:text-base font-medium w-full"
                    // onClick={handleSubmit}
                  >
                    Continue with Facebook
                  </button>
                </div>
                
                <div className="flex justify-center items-center border border-black  rounded-lg">
                  <img src={Google} alt="google" className="pl-5 w-14" />
                  <button
                    className="p-5 px-8  text-black text-sm md:text-base font-medium w-full"
                    // onClick={handleSubmit}
                  >
                    Continue with Apple
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogInForm;
