import React from "react";
import { NavBar, SmallFooter } from "../components";

const Layout2 = ({ children }) => {
  return (
    <>
      <NavBar />
      {children}
      <SmallFooter />
    </>
  );
};

export default Layout2;
