import React from "react";
import Layout1 from "../../Layouts/Layout1";
import Homebanner from "../common/Homebanner/Homebanner";
import Housecart from "../common/HouseCard/HouseCard";
import Developmentcart from "../common/Development/Developmentcart";
import BenefitsCard from "../common/BenefitCard/BenefitsCard";
import NewsCard from "../common/NewsCard/NewsCard";
import CompareLoans from "../common/CompareLoansCard/CompareLoans";
import FilterScreen from "../common/FilterScreen/FilterScreen";

const MainDashBoard = () => {
  return (
    <Layout1>
      <Homebanner />
      <div className="px-5 mb-10">
        <div className="container mx-auto">
          <FilterScreen />
          <Housecart />
          <Developmentcart />
          {/* <BenefitsCard /> */}
          {/* <NewsCard /> */}
          {/* <CompareLoans /> */}
        </div>
      </div>
    </Layout1>
  );
};

export default MainDashBoard;
