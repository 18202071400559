import React from "react";
import info from "../../../assets/infoOutline.png";
import dollerOutline from "../../../assets/dollerOutline.png";
import watchOutline from "../../../assets/watchOutline.png";
import soldOutline from "../../../assets/soldOutline.png";
import HomeOutline from "../../../assets/HomeOutline.png";

const AgentOverviewCard = ({ AgentData }) => {
  const PerformanceData = [
    {
      Img: dollerOutline,
      Title: "$ 822k",
      Description: "Median sold price",
    },
    {
      Img: watchOutline,
      Title: "20",
      Description: "Median days advertised",
    },
    {
      Img: soldOutline,
      Title: "110",
      Description: "Properties sold (as lead agent)",
    },
    {
      Img: HomeOutline,
      Title: "4",
      Description: "Properties sold (as secondary agent)",
    },
  ];


  return (
    <div
      className="flex flex-col justify-center items-start bg-[#FFFFFF] rounded-xl shadow-md hover:shadow-lg p-4 md:p-6"
    >
      <div className="w-full flex justify-between sm:justify-start items-center gap-3">
        <div className="text-[#171717] font-bold text-base md:text-lg lg:text-xl">
          {AgentData?.first_name}'s performance snapshot
        </div>
        <img src={info} alt="icon" className="w-4 cursor-pointer" />
      </div>
      <div className="text-[#404040] font-medium text-sm md:text-sm lg:text-base mt-2">
        Performance in the last 12 months on realestate.com.au.
      </div>

      <div className="w-full flex flex-col justify-start items-start gap-2 md:gap-4 mt-4 md:mt-6">
        <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6">
          {PerformanceData?.length > 0 &&
            PerformanceData?.map((d, index) => (
              <div
                key={index}
                className="w-full flex flex-col justify-center items-center gap-1 bg-[#F5F5F5] rounded-lg p-4 md:p-6"
              >
                <img src={d?.Img} alt="icon" className="w-7 cursor-pointer" />
                <div className="text-[#171717] text-center font-bold text-sm md:text-base lg:text-lg mt-2">
                  {d?.Title}
                </div>
                <div className="text-[#525252] text-center font-semibold text-sm md:text-sm">
                  {d?.Description}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AgentOverviewCard;
