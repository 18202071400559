import React from "react";
import Layout2 from "../../Layouts/Layout2";
import email from "../../assets/email.png";
import lock from "../../assets/lock.png";
import sideArrow from "../../assets/side-arrow.png";
import AlertsPopup from "../common/SettingPopup/SettingPopup";
import { useState } from "react";
import emailicon from "../../assets/emailicon.png";
import phone from "../../assets/phone.png"

const AccountSetting = () => {
  const [Iscale, setIscal] = useState(false);
  const [alertdata, setAlertdata] = useState({});

  const data = [

    {
      id: 0,
      maintitle: "Your property journey",
      content: "Recommended property information and tools based on your searches and activity.",
      email: emailicon,
      name: "Email",
      checkbox: "checkbox"

    },
    {
      id: 1,
      maintitle: "Saved search alerts",
      content: "Manage what alerts you get when you've saved a search.",
      email: emailicon,
      name: "Email",
      push: phone,
      name1: "push",
      con: "Download the app for push notifications",
      link: "Manage individual saved searches here",
      checkbox: "checkbox"

    },
    {
      id: 2,
      maintitle: "Property updates",
      content: "Notifications about properties you've shown interest in.",
      push: phone,
      name1: "push",
      con: "Download the app for push notifications",
    },
    {
      id: 3,
      maintitle: "Promoted residential properties",
      content: "Notifications about residential properties relevant to your search. ",
      email: emailicon,
      name: "Email",
      checkbox: "checkbox"

    },
    {
      id: 4,
      maintitle: "Promoted new developments",
      content: "Recommended new developments and property projects based on your searches and activity.",
      email: emailicon,
      name: "Email",
      checkbox: "checkbox"

    },
    {
      id: 5,
      maintitle: "Property updates",
      content: "Relates to the bell icon and notifications about your saved properties you've shown interest in.",
      checkbox: "checkbox",
      bordertop: "mt-6"

    },
    {
      id: 6,
      maintitle: "Market updates",
      content: "Market data, recent sales, auction results and updates on properties you like.",
      email: emailicon,
      name: "Email",
    },
    {
      id: 7,
      maintitle: "Sales and auction results",
      content: "Latest auction results and property sales.",
      push: phone,
      name1: "push",
      con: "Download the app for push notifications",
    },
    {
      id: 8,
      maintitle: "Property news and guides",
      content: "The latest property news, guides and inspiration.",
      email: emailicon,
      name: "Email",
    },
    {
      id: 9,
      maintitle: "Personalised ads",
      content: "Advertising tailored to you based on your activity and the information you've provided. If you opt out, you'll still get ads but they won't be tailored to you.",
      checkbox: "checkbox"

    },
    {
      id: 10,
      maintitle: "Suggested properties",
      content: "Property suggestions that match your activity and searches.",
      checkbox: "checkbox"

    },
  ]

  return (
    <Layout2>
      <div className="px-5 pt-28">
        <div className="container mx-auto">
          {/* -------- Start Account overview -------- */}
          <div className="flex flex-col justify-start  bg-white rounded-2xl shadow-sm hover:shadow-md p-6 md:p-8">
            <div className="text-start font-extrabold text-[#171717] text-lg md:text-2xl lg:text-3xl">
              Account overview
            </div>
            <div className="flex flex-col md:flex-row justify-start gap-5 mt-8">
              <div className="w-full md:w-[50%] flex flex-col justify-start">
                <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
                  Email
                </div>
                <div className="flex justify-between items-center border border-[#737373] rounded-[28px] p-4 mt-3">
                  <div className="flex justify-start items-center">
                    <img src={email} alt="icon" className="w-4 lg:w-5 mr-2" />
                    <div className="font-medium text-[#737373] text-xs md:text-sm lg:text-base">
                      josh@integrapaint.com.au
                    </div>
                  </div>
                  <button className="font-medium text-[#737373] text-xs md:text-sm lg:text-base cursor-pointer">
                    Update
                  </button>
                </div>
              </div>

              <div className="w-full md:w-[50%] flex flex-col justify-start">
                <div className="font-medium text-[#171717] text-xs md:text-sm lg:text-base">
                  Password
                </div>
                <div className="flex justify-between items-center border border-[#737373] rounded-[28px] p-4 mt-3">
                  <div className="flex justify-start items-center">
                    <img src={lock} alt="icon" className="w-4 lg:w-5 mr-2" />
                    <div className="font-medium text-[#737373] text-xs md:text-sm lg:text-base">
                      ***********
                    </div>
                  </div>
                  <button className="font-medium text-[#737373] text-xs md:text-sm lg:text-base cursor-pointer">
                    Update
                  </button>
                </div>
              </div>
            </div>

            <div className="font-semibold text-[#404040] text-xs md:text-sm lg:text-base mt-8">
              Sign out on all devices
            </div>
            <div className="font-medium text-[#737373] text-xs md:text-sm lg:text-base mt-2">
              Lost a device or recently used a public computer? Protect your
              account by signing out on all devices.
            </div>
            <div className="font-medium text-[#737373] text-xs md:text-sm lg:text-base my-5 md:my-7 cursor-pointer">
              <button className="border border-[#737373] rounded-[28px] py-3 px-5">
                Sign out on all devices
              </button>
            </div>
            <div className="font-medium text-[#E5002A] text-xs md:text-sm lg:text-base cursor-pointer">
              Delete account
            </div>
          </div>
          {/* -------- End Account overview -------- */}

          {/* -------- Start Notification settings -------- */}
          <div className="flex flex-col justify-start  bg-white rounded-2xl shadow-sm hover:shadow-md p-6 md:p-8 mt-10">
            <div className="text-start font-extrabold text-[#171717] text-lg md:text-2xl lg:text-3xl">
              Notification settings
            </div>
            <div className="flex flex-col justify-start">
              <div className="font-semibold text-[#171717] text-sm md:text-base lg:text-lg mt-5">
                Property journey
              </div>

              <div
                onClick={() => {
                  setIscal(true);
                  setAlertdata(data[0])

                }}
                className="flex justify-between items-center border border-[#E5E5E5] rounded-[50px] py-4 px-7 mt-3 cursor-pointer"
              >
                <div className="flex flex-col justify-start">
                  <div className="font-medium text-[#000000] text-xs md:text-sm lg:text-base">
                    Personalised advertising
                  </div>
                  <div className="font-normal text-[#171717] text-xs">
                    On: Email
                  </div>
                </div>
                <img src={sideArrow} alt="icon" className="h-4" />
              </div>
            </div>

            <div className="flex flex-col justify-start">
              <div className="font-semibold text-[#171717] text-sm md:text-base lg:text-lg mt-8">
                Properties
              </div>

              <div
                onClick={() => {
                  setIscal(true);
                  setAlertdata(data[1])
                }}
                className="flex justify-between items-center border border-[#E5E5E5] rounded-[50px] py-4 px-7 mt-3 cursor-pointer"
              >
                <div className="flex flex-col justify-start">
                  <div className="font-medium text-[#000000] text-xs md:text-sm lg:text-base">
                    Saved search alerts
                  </div>
                  <div className="font-normal text-[#171717] text-xs">
                    On: Email
                  </div>
                </div>
                <img src={sideArrow} alt="icon" className="h-4" />
              </div>

              <div
                onClick={() => {
                  setIscal(true);
                  setAlertdata(data[2])
                }}
                className="flex justify-between items-center border border-[#E5E5E5] rounded-[50px] py-4 px-7 mt-3 cursor-pointer"
              >
                <div className="flex flex-col justify-start">
                  <div className="font-medium text-[#000000] text-xs md:text-sm lg:text-base">
                    Property updates
                  </div>
                  <div className="font-normal text-[#171717] text-xs">off</div>
                </div>
                <img src={sideArrow} alt="icon" className="h-4" />
              </div>

              <div
                onClick={() => {
                  setIscal(true);
                  setAlertdata(data[3])
                }}
                className="flex justify-between items-center border border-[#E5E5E5] rounded-[50px] py-4 px-7 mt-3 cursor-pointer">
                <div className="flex flex-col justify-start">
                  <div className="font-medium text-[#000000] text-xs md:text-sm lg:text-base">
                    Promoted residential properties
                  </div>
                  <div className="font-normal text-[#171717] text-xs">
                    On: Email
                  </div>
                </div>
                <img src={sideArrow} alt="icon" className="h-4" />
              </div>

              <div
                onClick={() => {
                  setIscal(true);
                  setAlertdata(data[4])
                }}
                className="flex justify-between items-center border border-[#E5E5E5] rounded-[50px] py-4 px-7 mt-3 cursor-pointer">
                <div className="flex flex-col justify-start">
                  <div className="font-medium text-[#000000] text-xs md:text-sm lg:text-base">
                    Promoted new developments
                  </div>
                  <div className="font-normal text-[#171717] text-xs">
                    On: Email
                  </div>
                </div>
                <img src={sideArrow} alt="icon" className="h-4" />
              </div>
            </div>
          </div>
          {/* -------- End Notification settings -------- */}

          {/* -------- Start Data privacy -------- */}
          <div className="flex flex-col justify-start  bg-white rounded-2xl shadow-sm hover:shadow-md p-6 md:p-8 my-10">
            <div className="text-start font-extrabold text-[#171717] text-lg md:text-2xl lg:text-3xl">
              Data privacy
            </div>
            <div className="flex flex-col justify-start">
              <div
                onClick={() => {
                  setIscal(true);
                  setAlertdata(data[9])
                }}
                className="flex justify-between items-center border border-[#E5E5E5] rounded-[50px] py-4 px-7 mt-3 cursor-pointer">
                <div className="flex flex-col justify-start">
                  <div className="font-medium text-[#000000] text-xs md:text-sm lg:text-base">
                    Personalised advertising
                  </div>
                  <div className="font-normal text-[#171717] text-xs">On</div>
                </div>
                <img src={sideArrow} alt="icon" className="h-4" />
              </div>

              <div onClick={() => {
                setIscal(true);
                setAlertdata(data[10])
              }} className="flex justify-between items-center border border-[#E5E5E5] rounded-[50px] py-4 px-7 mt-3 cursor-pointer">
                <div className="flex flex-col justify-start">
                  <div className="font-medium text-[#000000] text-xs md:text-sm lg:text-base">
                    Suggested properties
                  </div>
                  <div className="font-normal text-[#171717] text-xs">off</div>
                </div>
                <img src={sideArrow} alt="icon" className="h-4" />
              </div>

              <div
                onClick={() => {
                  setIscal(true);
                  setAlertdata(data[5])
                }}
                className="flex justify-between items-center border border-[#E5E5E5] rounded-[50px] py-4 px-7 mt-3 cursor-pointer">
                <div className="flex flex-col justify-start">
                  <div className="font-medium text-[#000000] text-xs md:text-sm lg:text-base">
                    Property updates
                  </div>
                  <div className="font-normal text-[#171717] text-xs">On</div>
                </div>
                <img src={sideArrow} alt="icon" className="h-4" />
              </div>
            </div>
          </div>
          {/* -------- End Data privacy -------- */}
        </div>
      </div>

      <AlertsPopup
        setIsOpen={setIscal}
        IsOpen={Iscale}
        data={alertdata}
      />
    </Layout2>
  );
};

export default AccountSetting;