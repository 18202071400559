import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import FounderImageFirst from "../../../assets/drem.png";
import FounderImageSecond from "../../../assets/drem1.png";
import FounderImageThired from "../../../assets/drem2.png";
import FounderImageFourt from "../../../assets/drem3.png";
import righta from "../../../assets/lefta.png";
import lefta from "../../../assets/righta.png";
import like from "../../../assets/like.svg";
import location from "../../../assets/location.svg";
import icon from "../../../assets/icon.svg";
import icon1 from "../../../assets/icon1.svg";
import icon2 from "../../../assets/icon2.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import axiosInstance from "../../../apiInstances/axiosInstance";
import { BACKEND_BASE_URL } from "../../../apiInstances/baseurl";

const Housecart = () => {
  const [clientSliderData, setClientSliderData] = useState([]);
  console.log("🚀 ~ Housecart ~ clientSliderData:", clientSliderData)

  const newProperty = () => {
    axiosInstance
      .get(`/propaties`)

      .then((res) => {
        const myData = res?.data?.data;
        const arrData = myData.map((e, i) => {
          return {
            id: e?.id,
            clientImage: e?.image,
            head: e?.heading,
            like: like,
            locations: location,
            place: e?.address,
            discription: e?.discription,

            iconWithCount: [
              {
                icon: icon,
                count: e?.Bedrooms,
              },
              {
                icon: icon1,
                count: e?.toilets,
              },
              {
                icon: icon2,
                count: e?.Bathrooms,
              },
            ],
          };
        });
        setClientSliderData(arrData);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    newProperty();
  }, []);

  // const ClientSliderData = [
  //   {
  //     id: 1,
  //     clientImage: [
  //       FounderImageFirst,
  //       FounderImageSecond,
  //       FounderImageThired,
  //       FounderImageFourt,
  //     ],
  //     head: "Aqualand Australia",
  //     like: like,
  //     locations: location,
  //     place: "North Sydeny, NSW",
  //     iconWithCount: [
  //       {
  //         icon: icon,
  //         count: "1",
  //       },
  //       {
  //         icon: icon1,
  //         count: "2",
  //       },
  //       {
  //         icon: icon2,
  //         count: "3",
  //       },
  //     ],
  //   },
  // ];

  // const ClientSliderData = [
  //   {
  //     id: 1,
  //     clientImage: [
  //       FounderImageFirst,
  //       FounderImageSecond,
  //       FounderImageThired,
  //       FounderImageFourt,
  //     ],
  //     head: "Aqualand Australia",
  //     like: like,
  //     locations: location,
  //     place: "North Sydeny, NSW",
  //     iconWithCount: [
  //       {
  //         icon: icon,
  //         count: "1",
  //       },
  //       {
  //         icon: icon1,
  //         count: "2",
  //       },
  //       {
  //         icon: icon2,
  //         count: "3",
  //       },
  //     ],
  //     cont: "A property description is the written portion of a real estate listing that describes the details of a home for sale or lease. Descriptions account for roughly one-third of a listing and are accompanied by property information (i.e. the number of bedrooms) and photographs. The goal of a creative property description is to attract home buyers. Listing descriptions need colorful words to paint a clear mental picture of a home’s features and benefits, over and above the accompanying photographs.",
  //   },
  //   {
  //     id: 2,
  //     clientImage: [
  //       FounderImageSecond,
  //       FounderImageFirst,
  //       FounderImageThired,
  //       FounderImageFourt,
  //     ],
  //     head: "Ray White Lover North Shore Groupa",
  //     like: like,
  //     locations: location,
  //     place: "North Bridg, NSW",
  //     iconWithCount: [
  //       {
  //         icon: icon,
  //         count: "1",
  //       },
  //       {
  //         icon: icon1,
  //         count: "2",
  //       },
  //       {
  //         icon: icon2,
  //         count: "3",
  //       },
  //     ],
  //     cont: "A property description is the written portion of a real estate listing that describes the details of a home for sale or lease. Descriptions account for roughly one-third of a listing and are accompanied by property information (i.e. the number of bedrooms) and photographs. The goal of a creative property description is to attract home buyers. Listing descriptions need colorful words to paint a clear mental picture of a home’s features and benefits, over and above the accompanying photographs.",
  //   },
  //   {
  //     id: 3,
  //     clientImage: [
  //       FounderImageThired,
  //       FounderImageSecond,
  //       FounderImageFirst,
  //       FounderImageFourt,
  //     ],
  //     head: "MC Grath Projects",
  //     like: like,
  //     locations: location,
  //     place: "Castle Hill, NSW",
  //     iconWithCount: [
  //       {
  //         icon: icon,
  //         count: "1",
  //       },
  //       {
  //         icon: icon1,
  //         count: "2",
  //       },
  //       {
  //         icon: icon2,
  //         count: "3",
  //       },
  //     ],
  //     cont: "A property description is the written portion of a real estate listing that describes the details of a home for sale or lease. Descriptions account for roughly one-third of a listing and are accompanied by property information (i.e. the number of bedrooms) and photographs. The goal of a creative property description is to attract home buyers. Listing descriptions need colorful words to paint a clear mental picture of a home’s features and benefits, over and above the accompanying photographs.",
  //   },
  //   {
  //     id: 4,
  //     clientImage: [
  //       FounderImageFourt,
  //       FounderImageThired,
  //       FounderImageSecond,
  //       FounderImageFirst,
  //     ],
  //     head: "Stockwell Development Group Pty ",
  //     like: like,
  //     locations: location,
  //     place: "South Brisbane, QLD",
  //     iconWithCount: [
  //       {
  //         icon: icon,
  //         count: "1",
  //       },
  //       {
  //         icon: icon1,
  //         count: "2",
  //       },
  //       {
  //         icon: icon2,
  //         count: "3",
  //       },
  //     ],
  //     cont: "A property description is the written portion of a real estate listing that describes the details of a home for sale or lease. Descriptions account for roughly one-third of a listing and are accompanied by property information (i.e. the number of bedrooms) and photographs. The goal of a creative property description is to attract home buyers. Listing descriptions need colorful words to paint a clear mental picture of a home’s features and benefits, over and above the accompanying photographs.",
  //   },

  // ];
  return (
    <>
      <div className="my-8 ">
        <div className="flex justify-between lg:mx-40">
          <h2 className="font-semibold xl:text-3xl lg:text-2xl  md:text-xl text-lg text-[#262626]">
            New Properties for Sale
          </h2>
          <div className="flex my-auto mb-auto gap-2">
            <div>
              <img className="prev cursor-pointer w-10  " src={righta} alt="" />
            </div>
            <div>
              <img className="next cursor-pointer w-10 " src={lefta} alt="" />
            </div>
          </div>
        </div>
        {/* <p className="lg:text-base text-sm text-[#737373]">
          A property description is the written portion of a real estate listing
          that describes the details of a home for sale or lease.
        </p> */}
      </div>
      <div className="flex justify-center lg:mx-40">
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          modules={[Navigation]}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            // 1080: {
            //   slidesPerView: 4,
            //   spaceBetween: 30,
            // },
            1440: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1460: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
        >
          <div className="grid place-content-center ">
            {clientSliderData.map((e, index) => (
              <SwiperSlide key={index}>
                <div className="my-3 bg-white rounded-xl w-auto p-2 shadow-[6px_6px_10px_-5px_rgba(0,0,0,0.3)]">
                  <div>
                    <Carousel infinite={true} autoPlay={true}>
                      {e?.clientImage?.map((innerimg, index) => (
                        <div key={index}>
                          <img
                            src={`${BACKEND_BASE_URL}${innerimg}`}
                            alt="img"
                            className="h-56"
                          />
                        </div>
                      ))}
                    </Carousel>
                    <div className="flex justify-between mt-2 items-center px-2">
                      <h2 className="font-semibold lg:text-xl md:text-md text-lg text-[#262626]">
                        {e?.head?.substring(0, 15)}...
                      </h2>
                      <img src={e.like} alt="" className="cursor-pointer w-9" />
                    </div>
                    <div className="flex justify-start items-start py-3 px-2 gap-1 ">
                      <img src={location} alt="img" />
                      <p className="lg:text-sm text-xs h-10 text-[#737373]">
                        {e?.place}
                      </p>
                    </div>
                    <div className="flex gap-4 px-2 mt-3">
                      {e?.iconWithCount?.length > 0 &&
                        e?.iconWithCount?.map((elm, ind) => (
                          <div
                            key={ind}
                            className="flex gap-2 bg-[#F5F5F5] rounded-lg p-2"
                          >
                            <img src={elm?.icon} alt="" />
                            <span>{elm?.count}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <p className="h-[4.5rem] overflow-hidden  mt-3 px-3 lg:text-base text-sm text-[#737373]">
                    {e?.discription?.substring(0, 95)}...
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
      <div></div>
    </>
  );
};

export default Housecart;
