import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NavBar.css";
import logoSmall from "../../assets/logoSmall.png";
import sideArrow from "../../assets/side-arrow.png";
import Profile from "../../assets/Profile.png";

const NavBar = () => {
  const getPath = useLocation().pathname.split("/")?.[1];
  const navigate = useNavigate();

  const checkAuth = localStorage.getItem("Token");

  const headerData = [
    {
      id: 0,
      pathname: "/buy",
      pagename: "Buy",
    },
    {
      id: 1,
      pathname: "/rent",
      pagename: "Rent",
    },
    {
      id: 2,
      pathname: "/sold",
      pagename: "Sold",
    },
    {
      id: 3,
      pathname: "/new-homes",
      pagename: "New homes",
    },
    {
      id: 4,
      pathname: "/find-agents",
      pagename: "Find agents",
    },
    {
      id: 5,
      pathname: "/commercial",
      pagename: "Commercial",
    },
  ];

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <input type="checkbox" name="" id="" />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <ul className="menu-items">
            {headerData?.map((data, index) => (
              <div key={index}>
                {`/${getPath}` === data.pathname ? (
                  <li className="min-w-max effect-h">
                    <Link to={data.pathname}>
                      <div className="flex justify-center items-center font-semibold text-[#000000] rounded-3xl ">
                        {data.pagename}
                      </div>
                    </Link>
                  </li>
                ) : (
                  <li className="min-w-max">
                    <Link to={data.pathname}>
                      <div className="flex justify-center items-center font-normal text-[#404040] hover:text-[#000000] hover:font-semibold rounded-3xl ">
                        {data.pagename}
                      </div>
                    </Link>
                  </li>
                )}
              </div>
            ))}
          </ul>
          <div
            className="logo grid place-items-center cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={logoSmall}
              alt="logo"
              className="h-[30px] mt-1 sm:mt-0 sm:h-10 mx-5 rounded-xl cursor-pointer"
            />
            {/* <h1 className="w-[120px] bg-[#FFCCD3] rounded-lg text-[#FF444D] font-extrabold text-lg md:text-xl px-5 py-2 mx-5 cursor-pointer">
              La Rosa
            </h1> */}
          </div>
          {checkAuth === undefined || checkAuth === null ? (
            <div className="auth-button mx-5">
              <button
                className="sign text-black font-medium mx-5"
                onClick={() => {
                  navigate("/log-in");
                }}
              >
                <p className="flex justify-center items-center">
                  Sign in
                  <img src={sideArrow} alt="" className="pl-2 h-2" />
                </p>
              </button>
              <button
                className="join py-2 px-5 rounded-3xl border bg-[#E5002A] text-white hover:bg-white  hover:text-[#E5002A] hover:border-[#E5002A] font-medium"
                onClick={() => {
                  navigate("/sign-up");
                }}
              >
                Join
              </button>
            </div>
          ) : (
            <div className="auth-button mx-5">
              <img
                src={Profile}
                alt="profile"
                className="profile p-3"
                onClick={() => {
                  navigate("/dashbord");
                }}
              />
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default NavBar;
